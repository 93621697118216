import { useTranslate } from "../../../../customHooks";
import { useMemo, useCallback } from "react";
import { FormLayout, Input, Checkbox, RadioGroup, SubmitButton } from "@panwds/react-form";
import { useForm, FormProvider, Controller, useWatch } from "react-hook-form";
import { isValidIPAMPoolId } from "../../../../utils/validate";
import { Button, Subtitle } from "@panwds/react-ui";
import { useUpdateFirewallMutation } from "../../../../redux/services/firewalls-service";
import { extractFieldsWithConfig } from "../../../../utils/utils";
import { isEmpty } from "lodash";
import AWSServiceIPsTableComponent from "../Edit/EgressNatComponents/AWSServiceIPsTableComponent";
import { toast } from "../../../../components";

const Index = (props: { firewallData: Record<string, any>; isLoading: boolean; }) => {
    const translate = useTranslate();
    const [updateFirewall] = useUpdateFirewallMutation();

    const config = { Firewall: ["EgressNAT", "UpdateToken"], Status: ["PublicIPs"] };

    const transformFormDefaultValue = useMemo(() => {
        if (isEmpty(props?.firewallData)) {
            return undefined;
        }
    
        const newStateWithConfig: any = extractFieldsWithConfig(props?.firewallData, config);
    
        const updatedFirewall = {
            ...newStateWithConfig.Firewall,
            EgressNAT: {
                ...newStateWithConfig.Firewall?.EgressNAT,
                Settings: {
                    ...newStateWithConfig.Firewall?.EgressNAT?.Settings,
                    IPPoolType: newStateWithConfig.Firewall?.EgressNAT?.Settings?.IPPoolType || 'AWSService'
                }
            },
        };
    
        return {
            Firewall: updatedFirewall,
            Status: {
                ...newStateWithConfig?.Status,
            }
        };
    }, [props?.firewallData]);

    const formMethods = useForm({ defaultValues: transformFormDefaultValue });

    const { watch, formState: { errors, isDirty }, reset } = formMethods;
    
    const egressNatEnable = watch("Firewall.EgressNAT.Enabled");
    const iPoolType = useWatch({ control: formMethods.control, name: "Firewall.EgressNAT.Settings.IPPoolType" });

    const transformFormSubmitValue = useCallback((formData: Record<string, any>) => {
        if (isEmpty(formData)) {
            return;
        }
    
        const { Firewall, Status } = formData;
    
        let transformedData = {
            Firewall: {
                ...Firewall,
                EgressNAT: {
                    ...Firewall?.EgressNAT,
                    Settings: {
                        ...Firewall?.EgressNAT?.Settings,
                    },
                },
            },
        };
    
        if (Firewall?.EgressNAT?.Settings?.IPPoolType === 'AWSService') {
            delete transformedData.Firewall.EgressNAT.Settings.IPAMPoolId;
        }
    
        if (Status && Status.PublicIPs) {
            transformedData.Status = {
                PublicIPs: Status.PublicIPs,
            };
        }
        
        return transformedData;
    }, []);

    const onSubmit = useCallback(async (data) => {
        try {
            const result = await updateFirewall({
                payload: transformFormSubmitValue(data),
                region: props?.firewallData?.firewall?.Firewall?.Region || '',
            }).unwrap();
            toast.success("Firewall updated successfully");
        } catch (error: any) {
            toast.error(`${error?.code}: ${error?.error}`, { toastId: "update-firewall-egress-nat" });
        }
    }, [updateFirewall, transformFormSubmitValue, props?.firewallData?.firewall?.Firewall?.Region]);


    const handleCancel = () => {
        reset(transformFormDefaultValue);
    };

    const awsServiceOptions = [
        { value: 'AWSService', label: translate('resources.firewallsV2.awsService') },
        { value: 'BYOIP', label: translate('resources.firewallsV2.byoip') }
    ];

    return (
        <>
            <div className="tw-text-gray-800 tw-py-4 text-base tw-font-lato tw-font-medium tw-leading-5">
                {translate(`resources.firewallsV2.egressNat`)}
            </div>
            <FormProvider {...formMethods}>
                <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                    <FormLayout>
                        <Checkbox
                            {...formMethods.register("Firewall.EgressNAT.Enabled")}
                            label={translate(`resources.firewallsV2.enableEgressNat`)}
                            dataMetrics={"cloudngfw-firewall-edit-egressNatEnabled"}
                            description={translate(`resources.firewallsV2.enableEgressNatMutedText`)}
                        />

                        {egressNatEnable && (
                            <>
                                <Subtitle as={"div"} appearance={"semibold"} size={"sm"}>
                                    {translate(`resources.firewallsV2.ipAddressPool`)}
                                </Subtitle>
                                <Controller
                                    name="Firewall.EgressNAT.Settings.IPPoolType"
                                    control={formMethods.control}
                                    rules={{ required: 'Selection is required' }}
                                    render={({ field }) => (
                                        <RadioGroup
                                            {...field}
                                            defaultValue="AWSService"
                                            horizontal={true}
                                            items={awsServiceOptions.map(awsService => ({
                                                value: awsService.value,
                                                children: awsService.label
                                            }))}
                                        />
                                    )}
                                />
                                {iPoolType === 'BYOIP' && (
                                    <Input
                                        label={translate(`resources.firewallsV2.ipAddressPool`)}
                                        placeholder="Please enter a IPAM pool id (e.g., ipam-pool-0123456789abcdef)"
                                        {...formMethods.register("Firewall.EgressNAT.Settings.IPAMPoolId", {
                                            validate: isValidIPAMPoolId
                                        })}
                                        error={errors.Firewall?.EgressNAT?.Settings?.IPAMPoolId?.message}
                                        dataMetrics="cloudngfw-firewall-create-egress-nat-public-own-ips"
                                    />
                                )}
                                {!isEmpty(formMethods.getValues("Status.PublicIPs")) && (
                                    <AWSServiceIPsTableComponent
                                        value={formMethods.getValues("Status.PublicIPs")}
                                    />
                                )}
                            </>
                        )}

                        <div className="tw-flex tw-justify-end">
                            <Button onClick={handleCancel} disabled={!isDirty} style={{ marginRight: '5px' }}>
                                {translate(`generic.cancel`)}
                            </Button>
                            <SubmitButton type="submit" disabled={!isDirty}>
                                {translate(`generic.save`)}
                            </SubmitButton>
                        </div>
                    </FormLayout>
                </form>
            </FormProvider>
        </>
    );
};

export default Index;