import { FormLayout, Input, Select, SubmitButton } from '@panwds/react-form';
import { Button, Sheet, SheetBody } from "@panwds/react-ui";
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslate } from "../../../../../customHooks";
import { useGetLinkAccountsQuery, useGetXAccountsDetailsQuery } from '../../../../../redux/services/accounts-service';
import { intersection } from 'lodash';
import { toast } from 'react-toastify';

export const EndpointsCreate = (props: any) => {
    const { close, createEndpoint, allowlisteAwsAccounts } = props;
    const translate = useTranslate();
    const [nexttoken, setNextToken] = useState<string | null>(null);
    const [accountsList, setAccountsList] = useState<string[]>([]);
    const [vpcList, setVpcList] = useState<string[]>([]);
    const [subnetList, setSubentList] = useState<string[]>([]);
    const [currentAccount, setCurrentAccount] = useState<string>("");
    const [currentVpcId, setCurrentVpcId] = useState<string>("");

    const { data: accounts, error: accountsError, isLoading } = useGetLinkAccountsQuery({
        nexttoken: nexttoken || '',
        maxresults: 1000,
        onboarded: true,
        describe: true,
    }, {
        skip: nexttoken === undefined,
    });

    const { data: vpcs, error: vpcsError, isLoading: vpcsLoading } = useGetXAccountsDetailsQuery({
        accountId: currentAccount,
    }, {
        skip: !currentAccount,
    });

    const { data: subents, error: subentsError, isLoading: subnetsLoading } = useGetXAccountsDetailsQuery({
        accountId: currentAccount,
        vpcId: currentVpcId,
    }, {
        skip: !currentVpcId,
    });
   

    useEffect(() => {
        if (accounts) {
            const allowedAccounts = (allowlisteAwsAccounts) ?
                Array.isArray(allowlisteAwsAccounts) ? allowlisteAwsAccounts : allowlisteAwsAccounts.split(",") : [];
            const filteredAccounts = intersection(accounts?.items, allowedAccounts);
            setAccountsList(filteredAccounts.map((account: any) => ({
                name: account,
                value: account,
                id: account,
            })));
            if (accounts.nexttoken) {
                setNextToken(accounts.nexttoken);
            }
        }
    }, [accounts, allowlisteAwsAccounts]);
    
    useEffect(() => {
        setVpcList( vpcs?.items?.VpcInformations?.map((vpc: any) => ({
            name: vpc?.VpcName,
            value: vpc?.VpcId,
            id: vpc?.VpcId,
        })));
    }, [vpcs]);
    
    useEffect(() => {
        setSubentList(subents?.items?.VpcInformations[0]?.SubnetInformations?.map((subnet: any) => ({
            name: subnet?.SubnetName || subnet?.SubnetId,
            value: subnet?.SubnetId,
            id: subnet?.SubnetId,
        })));
    }, [subents]);

    const formMethods = useForm({});
    const {
        formState: { isDirty, isValid, }, watch, setValue
    } = formMethods
    
    const accountIdValue = watch("AccountId");
    const vpcIdValue = watch("VpcId");

    // Side effect based on the 'firstName' field change
    useEffect(() => {
        if (accountIdValue) {
            setCurrentAccount(accountIdValue);
            setValue("VpcId", "");
            setVpcList([]);
        }
    }, [accountIdValue]);

    useEffect(() => {
        if (vpcIdValue) {
            setCurrentVpcId(vpcIdValue);
            setValue("SubnetId", "");
            setSubentList([]);
        }
    }, [vpcIdValue]);
    
    const onSubmit = useCallback(
        async (data, e) => {
            e.preventDefault();
            createEndpoint(data);
        }, []
    );

    if (accountsError) {
        toast.error(accountsError?.error, {toastId: "enpoint-creates-get-accounts"});
    }
    if (vpcsError) {
        toast.error(vpcsError?.error, {toastId: "enpoint-creates-get-vpcs"});
    }
    if(subentsError) {
        toast.error(subentsError?.error, {toastId: "enpoint-creates-get-subnets"});
    }

    return (
        <>
         <Sheet
            onClose={() => close()}
            title={translate(`resources.firewallsV2.addEndpoint`)}
            isOpen
            showMask={true}
            position="md"
        >
            <SheetBody>
                <div style={{ position: 'relative' }}>
                    <FormProvider {...formMethods}>
                        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                            <FormLayout>
                                <Select
                                    items={accountsList}
                                    label={translate(`resources.firewallsV2.linkedAwsAccounts`)}
                                    name="AccountId"
                                    dataMetrics="cloudngfw-firewall-create-endpoint-account"
                                    isLoading={isLoading}
                                    placeholder={translate(`resources.firewallsV2.linkedAwsAccountsPlaceholder`)}
                                    simpleFormat
                                />
                                <Select
                                    items={vpcList}
                                    label={translate(`resources.firewallsV2.vpcId`)}
                                    name="VpcId"
                                    dataMetrics="cloudngfw-firewall-create-endpoint-vpcId"
                                    isLoading={vpcsLoading}
                                    placeholder={translate(`resources.firewallsV2.vpcSelectPlaceholder`)}
                                    simpleFormat
                                    disabled={!currentAccount}
                                />
                                <Select
                                    items={subnetList}
                                    label={translate(`resources.firewallsV2.subnetId`)}
                                    name="SubnetId"
                                    dataMetrics="cloudngfw-firewall-create-endpoint-subnetId"
                                    isLoading={subnetsLoading}
                                    placeholder={translate(`resources.firewallsV2.subnetSelectPlaceholder`)}
                                    simpleFormat
                                    disabled={!currentVpcId}
                                    />
                                    
                                <div hidden>
                                    <Input
                                        name="Mode"
                                        dataMetrics="cloudngfw-firewall-create-endpoint-mode"
                                        defaultValue='ServiceManaged'    
                                    />
                                </div>
                                
                                <div className="tw-flex tw-justify-end">
                                    <Button addClassName='tw-mr-2' appearance={"secondary"} onClick={() => props?.close()}>
                                        {translate("generic.cancel")}
                                    </Button>
                                    <SubmitButton disabled={!isValid || !isDirty}>{translate(`generic.add`)}</SubmitButton>
                                </div>
                            </FormLayout>
                        </form>
                    </FormProvider>
                </div>
            </SheetBody>
        </Sheet>
    </>
    );
};