import { useTranslate } from "../../../../customHooks";
import { composeValidators, isPort, minLength, maxLength, validSourceName } from "../../../../utils/validate";
import { Button, Link, Banner } from "@panwds/react-ui";
import { usePermissions } from "../../../../customHooks";
import { useCallback, useMemo, useState, useEffect } from "react";
import { FormLayout, Input, SubmitButton, Checkbox } from "@panwds/react-form";
import { isEmpty } from "lodash";
import { useUpdateFirewallMutation } from "../../../../redux/services/firewalls-service";
import { extractFieldsWithConfig } from "../../../../utils/utils";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import CustomIncludeExcludeNetworksTable from "./UserIDComponents/CustomIncludeExcludeNetworksTable";
import { CustomIncludeExcludeCreate } from "./UserIDComponents/CustomIncludeExcludeCreate";
import { CustomIncludeExcludeEdit } from "./UserIDComponents/CustomIncludeExcludeEdit";
import { makeStyles } from "@material-ui/styles";
import { toast } from "../../../../components";

type PanelType = "" | "createIncludeExclude" | "editIncludeExclude";

const useStyles = makeStyles((theme) => ({
    header: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '20px',
        color: "#333333",
        padding: '16px 0'
    }
}));

const Index = (props: { firewallData: Record<string, any>; isLoading: boolean; }) => {
    const translate = useTranslate();
    const classes = useStyles();
    const { permissions } = usePermissions();
    const region = new URLSearchParams(location.search).get("region");
    const [updateFirewall] = useUpdateFirewallMutation();
    const intl = useIntl();
    const [sidePanel, setSidePanel] = useState<PanelType>("");
    const [editData, setEditData] = useState(null);
    const cftLink = props?.firewallData?.Firewall?.UserID?.CFTURLLink;
    const userIDStatus = props?.firewallData?.Firewall?.UserID?.UserIDStatus;
    const [showSuccessBanner, setShowSuccessBanner] = useState(true);
    const [showErrorBanner, setShowErrorBanner] = useState(true);
    const isPending = /EndpointPending|DisablePending|UpdatePending/.test(userIDStatus);
    const isError = /EndpointFail|CommitFail|UpdateFail/.test(userIDStatus);
    const isSuccess = userIDStatus === "Enabled";

    const config = { Firewall: ["FirewallId", "Region", "UserID", "PrivateAccessConfig", "UpdateToken", "DeploymentUpdateToken"] };

    // Memoized function to transform the default form values
    const transformFormDefaultValue = useMemo(() => {
        if (isEmpty(props?.firewallData)) {
            return undefined;
        }
        // Extract fields from firewall describe response based on the config.
        // Extracts fields needed for firewall user id info form.
        const newStateWithConfig: any = extractFieldsWithConfig(props?.firewallData, config);

        return {
            Firewall: {
                ...newStateWithConfig?.Firewall,
                PrivateAccessConfig: {
                    ...newStateWithConfig?.Firewall?.PrivateAccessConfig,
                    Type: newStateWithConfig?.Firewall?.PrivateAccessConfig?.Type ?? "NetworkLoadBalancer"
                }
            },
        };
    }, [props?.firewallData]);

    /**
     * Transforms the form data for firewall update submission.
     * This function processes the form data, applies necessary transformations,
     * and prepares the data structure required for the firewall user id info update.
     */
    const transformFormSubmitValue = useCallback((formData: Record<string, any>) => {
        if (isEmpty(formData)) {
            return;
        }

        // Extract the necessary fields
        const { UserID } = formData.Firewall;

        // Convert port to integer if necessary
        const portAsInt = parseInt(UserID.Port, 10);

        // Create the transformed data object
        let transformedData = {
            ...formData.Firewall,
            UserID: {
                ...UserID,
                Port: isNaN(portAsInt) ? UserID.Port : portAsInt,
            },
        };

        return transformedData;
    }, []);

    const onSubmit = useCallback(async (data) => {
        try {
            const result = await updateFirewall({
                payload: transformFormSubmitValue(data),
            }).unwrap();
        }
        catch (error: any) {
            toast.error(`${error?.code}: ${error?.error}`, {toastId: "update-firewall-user-id"});
        }
    }, [transformFormSubmitValue]);

    const renderBanner = () => {
        if (isPending) {
            return (
                <Banner type="inline" appearance="warning" showIcon>
                    {translate('resources.firewallsV2.userid.pendingBannerMsg')}
                </Banner>
            );
        } else if (isError && showErrorBanner) {
            return (
                <Banner type="inline" appearance="error" showIcon>
                    {translate('resources.firewallsV2.userid.errorBannerMsg')}
                </Banner>
            );
        } else if (isSuccess && showSuccessBanner) {
            return (
                <Banner type="inline" appearance="success" showIcon onClose={() => setShowSuccessBanner(false)}>
                    {translate('resources.firewallsV2.userid.successBannerMsg')}
                </Banner>
            );
        }
        return null;
    };

    const formMethods = useForm({ defaultValues: transformFormDefaultValue });

    const { formState: { errors, isDirty }, control, setValue, getValues, watch, reset } = formMethods;

    const isUserIDEnabled = watch("Firewall.UserID.UserIDConfig");

    const extractTemplateURL = useCallback((url: string) => {
        const key = "templateURL";
        let start = url.indexOf(key);
        if (start === -1) {
            return null;
        }
        start += key.length + 1;
        const end = url.indexOf("&", start);
        const cftUrl = url.substring(start, end === -1 ? url.length : end);
        return decodeURIComponent(cftUrl);
    }, []);

    const builddownloadCFTLinks = useCallback((cftUrl: string) => {
        if (!cftUrl) return null;
        const cftTemplateUrl = extractTemplateURL(cftUrl);

        return (
            <a href={cftTemplateUrl || "#"} download style={{ color: "#006FCC", fontSize: "inherit" }}>
                {translate(`resources.firewallsV2.userid.downloadCFT`)}
            </a>
        );
    }, []);

    const builLaunchCFTLinks = useCallback((cftUrl: string) => {
        if (!cftUrl) return null;

        return (
            <Link external href={cftUrl} target="_blank" rel="noreferrer" className="tw-text-blue-600 tw-text-inherit">
                {translate(`resources.firewallsV2.userid.launchCFT`)}
            </Link>
        );
    }, []);

    const createIncludeExcludeNetwork = useCallback((data) => {
        const currentCustomIncludeExcludeNetworks = getValues("Firewall.UserID.CustomIncludeExcludeNetwork");
        const updatedNetworks = [...currentCustomIncludeExcludeNetworks, data];
        setValue("Firewall.UserID.CustomIncludeExcludeNetwork", updatedNetworks, { shouldDirty: true });
        setSidePanel("");
    }, [getValues, setValue]);

    const deleteIncludeExcludeNetwork = useCallback((row) => {
        const currentCustomIncludeExcludeNetworks = getValues("Firewall.UserID.CustomIncludeExcludeNetwork");
        setValue("Firewall.UserID.CustomIncludeExcludeNetwork", currentCustomIncludeExcludeNetworks.filter(
            (network: any) => network?.Name !== row?.Name), { shouldDirty: true });
    }, [setValue, getValues]);

    const updateIncludeExcludeNetwork = useCallback((data) => {
        const currentCustomIncludeExcludeNetworks = getValues("Firewall.UserID.CustomIncludeExcludeNetwork");
        const updatedNetworks = currentCustomIncludeExcludeNetworks.map(
            (network: any) => (network.Name === data.Name ? data : network));
        setValue("Firewall.UserID.CustomIncludeExcludeNetwork", updatedNetworks, { shouldDirty: true });
        setSidePanel("");
    }, [setValue, getValues]);

    const handleRowClick = (rowData: any) => {
        setEditData(rowData);
        setSidePanel("editIncludeExclude");
    };


    useEffect(() => {
        if (isUserIDEnabled === false) {
            setShowErrorBanner(false);
        }
    }, [isUserIDEnabled]);

    const handleCancel = () => {
        reset(transformFormDefaultValue);
    };

    return (
        <div>
            <div className={classes.header}>{translate(`resources.firewallsV2.userId`)}</div>
            {renderBanner()}
            <FormProvider {...formMethods}>
                <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                    <FormLayout>
                        <Checkbox
                            name="Firewall.UserID.UserIDConfig"
                            label={translate("resources.firewallsV2.userid.enableUserID")}
                            disabled={isPending}
                            dataMetrics="cloudngfw-firewall-edit-enable-aws-network-load-balancer"
                            description={intl.formatMessage(
                                {
                                    id: "resources.firewallsV2.userid.enableUserIDHelpText",
                                },
                                {
                                    document: (
                                        <Link
                                            dataMetrics="cloudngfw-aws-network-load-balancer-help"
                                            external
                                            href="https://docs.paloaltonetworks.com/pan-os/10-2/pan-os-admin/user-id"
                                        >
                                            {translate(`generic.document`)}
                                        </Link>
                                    ),
                                }
                            )}
                        />
                          
                        {isUserIDEnabled && (
                            <>
                                <Input
                                    name="Firewall.PrivateAccessConfig.ResourceID"
                                    label={translate(`resources.firewallsV2.userid.amazonResourceARN`)}
                                    dataMetrics="cloudngfw-firewall-edit-amazon-resource-arn"
                                    disabled={!isDirty || isPending || isSuccess}
                                    requiredAsterisk
                                    description={
                                        <FormattedMessage
                                            id="resources.firewallsV2.userid.amazonResourceARNHelpText"
                                            values={{
                                                downloadCFT: builddownloadCFTLinks(cftLink),
                                                launchCFT: builLaunchCFTLinks(cftLink),
                                            }}
                                        />
                                    }
                                    register={{ validate: (value) => composeValidators(minLength(20))(value) }}
                                    tooltip={translate(`resources.firewallsV2.userid.amazonResourceARNTooltip`)}
                                />
                                <Input
                                    name="Firewall.UserID.Port"
                                    label={translate(`resources.firewallsV2.userid.port`)}
                                    dataMetrics="cloudngfw-firewall-edit-amazon-resource-arn"
                                    description={translate(`resources.firewallsV2.userid.portHelpText`)}
                                    requiredAsterisk
                                    disabled={!isDirty || isPending || isSuccess}
                                    register={{ validate: (value) => isPort(value) }}
                                />
                                <Input
                                    name="Firewall.UserID.AgentName"
                                    label={translate(`resources.firewallsV2.userid.sourceName`)}
                                    dataMetrics="cloudngfw-firewall-edit-source-name"
                                    description={translate(`resources.firewallsV2.userid.sourceNameHelpText`)}
                                    requiredAsterisk
                                    disabled={!isDirty || isPending || isSuccess}
                                    register={{ validate: (value) => composeValidators(validSourceName, maxLength(31))(value) }}
                                    tooltip={translate(`resources.firewallsV2.userid.sourceNameTooltip`)}
                                />
                                <Input
                                    name={"Firewall.UserID.CollectorName"}
                                    label={translate(`resources.firewallsV2.userid.collectorName`)}
                                    dataMetrics="cloudngfw-firewall-edit-collector-name"
                                    description={intl.formatMessage(
                                        {
                                            id: "resources.firewallsV2.userid.collectorNameHelpText",
                                        },
                                        {
                                            document: (
                                                <Link
                                                    dataMetrics="cloudngfw-collector-name-help"
                                                    external
                                                    href="https://docs.paloaltonetworks.com/panorama/10-2/panorama-admin/manage-firewalls/redistribute-user-id-information-to-managed-firewalls"
                                                >
                                                    {translate(`generic.document`)}
                                                </Link>
                                            ),
                                        }
                                    )}
                                    disabled={!isDirty || isPending || isSuccess}
                                    register={{ required: false }}
                                />
                                <Input
                                    name="Firewall.UserID.SecretKeyARN"
                                    label={translate(`resources.firewallsV2.userid.awsSecretKeyARN`)}
                                    dataMetrics="cloudngfw-firewall-edit-aws-secret-key-arn"
                                    description={intl.formatMessage(
                                        {
                                            id: "resources.firewallsV2.userid.awsSecretKeyARNHelpText",
                                        },
                                        {
                                            document: (
                                                <Link
                                                    dataMetrics="cloudngfw-collector-name-help"
                                                    external
                                                    href="https://docs.aws.amazon.com/secretsmanager/latest/userguide/whats-in-a-secret.html"
                                                >
                                                    {translate(`generic.document`)}
                                                </Link>
                                            ),
                                        }
                                    )}
                                    disabled={!isDirty || isPending || isSuccess}
                                    register={{ required: false }}
                                />
                                <Controller
                                    name="Firewall.UserID.CustomIncludeExcludeNetwork"
                                    control={control}
                                    defaultValue={[]}
                                    render={({ field }) => (
                                        <CustomIncludeExcludeNetworksTable
                                            deleteNetwork={deleteIncludeExcludeNetwork}
                                            setSidePanel={setSidePanel}
                                            onRowClick={handleRowClick}
                                            {...field}
                                            label="Custom Field"
                                            userIDStatus={formMethods.watch("Firewall.UserID.UserIDStatus")}
                                            disabled={!isDirty || isPending || isSuccess}
                                        />
                                    )}
                                />
                            </>
                        )}
                        <div className="tw-flex tw-justify-end">
                            <Button onClick={handleCancel} disabled={!isDirty} style={{ marginRight: '5px' }}>
                                {translate(`generic.cancel`)}
                            </Button>
                            <SubmitButton disabled={!isDirty}>
                                {translate(`generic.save`)}
                            </SubmitButton>
                        </div>
                    </FormLayout>
                </form>
                {sidePanel === "createIncludeExclude" && (
                    <CustomIncludeExcludeCreate
                        close={() => setSidePanel("")}
                        createNetwork={createIncludeExcludeNetwork}
                        items={getValues("Firewall.UserID.CustomIncludeExcludeNetwork")}
                    />
                )}
                {sidePanel === "editIncludeExclude" && (
                    <CustomIncludeExcludeEdit
                        close={() => setSidePanel("")}
                        updateNetwork={updateIncludeExcludeNetwork}
                        items={getValues("Firewall.UserID.CustomIncludeExcludeNetwork")}
                        initialData={editData} // Pass the data to be edited
                    />
                )}
            </FormProvider>
        </div>
    );
};

export default Index;