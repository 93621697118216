import React, { forwardRef, useMemo } from 'react';
import { Table } from '../../../../../components/PANWDSElements';
import { ColumnOptions, RowInstance } from '@panwds/react-table';
import { useTranslate } from "../../../../../customHooks";
import { Button, Tooltip } from '@panwds/react-ui';
import { DeleteIcon } from '@panwds/icons';
import { isEmpty } from 'lodash';

const FirewallEndpointsTable = forwardRef<HTMLInputElement, any>(
    ({ ...props }, ref) => {
    const {
        value,
        setSidePanel,
        deleteEndpoint,
        setEditEndpointData,
        allowListedAwsAccounts,
        endpointAction,
    } = props;
    
    const translate = useTranslate();
  
    const columns = useMemo<ColumnOptions<any>[]>(() => [{
        accessor: 'EndpointId',
        Header: translate('resources.firewallsV2.endpointId'),
        Cell: ({ row }: { row: RowInstance<object> }) => {
            return (row?.original?.Status === "Accepted" && (endpointAction !== "createEndpoint" && endpointAction !== "deleteEndpoint")) ? <span className='tw-text-blue-600 tw-hover:text-blue-600 tw-cursor-pointer'
              onClick={() => setEditEndpointData(row?.original)}
            >{row?.original?.EndpointId}</span>
              : <span>{row?.original?.EndpointId}</span>
        }
    }, {
        accessor: 'AccountId',
        Header: 'Account ID'
    },{
        accessor: 'VpcId',
        Header: translate('resources.firewallsV2.vpcId')
    },{
        accessor: 'SubnetId',
        Header: translate('resources.firewallsV2.subnetId'),
    },{
        accessor: 'Status',
        Header: translate('resources.firewallsV2.endpointsStatus')
    },{
        accessor: 'actions',
        Header: translate(`generic.actions`),
        Cell: ({ row }: { row: RowInstance<object> }) => {
          return (
            <>
              <Tooltip label={translate(`generic.delete`)}>
                <button
                  className="tw-mr-2 tw-border-none tw-bg-transparent"
                  onClick={() => {
                    deleteEndpoint(row?.original);
                  }}
                  disabled={ row?.original?.EndpointId && (row?.original?.Mode === "CustomerManaged" || (endpointAction === "createEndpoint" || endpointAction === "editEndpoint"))}
                >
                  <DeleteIcon
                    className="tw-text-zinc-400 dark:tw-text-gray-600 dark:group-hover:tw-text-gray-400 tw-cursor-pointer"
                    size="sm"
                    data-testid="firewall-edit-endpoint-delete"
                  />
                </button>
              </Tooltip>
            </>
          );
        },
        sticky: true,
      },], []);
    
    const batchActions = useMemo(() => { 
        return {
            rightControl: (
                <Button
                  dataTestId="create-endpoint"
                  onClick={() => {setSidePanel('createEndpoint') } }
                  appearance="primary"
                  disabled={isEmpty(allowListedAwsAccounts) || (endpointAction === "editEndpoint" || endpointAction === "deleteEndpoint")}
                >
                    {translate(`resources.firewallsV2.addEndpoint`)}
                </Button>
            ),
        };
    }, [allowListedAwsAccounts]);
        
    return (
    <div>
        <Table
            tableComponentData={value || []}
            tableComponentColumns={columns}
            tableComponentEmptyStateProps={{
                heading: translate('resources.firewallsV2.emptyFirewallsTable'),
            }}
            tableBatchAction={batchActions}
            tableComponentFilterBar={{
                hideAddFilter: true,
                filterConfig: undefined,
            }}
            tableTitle={translate('resources.firewallsV2.title')}
            />
    </div>
  );
    });

export default FirewallEndpointsTable;