import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { History } from 'history'
import * as ReduxReducers from './slices';
import * as ReduxActions from './actions';
import ReduxResources from './utils/enums';
import { firewallsService } from './services/firewalls-service';
import { regionsService } from './services/regions-service';
import { userRolesService } from './services/user-roles-service';
import { rulesService } from './services/rules-service';
import { accountsService } from './services/accounts-service';
import { logProfileService } from './services/log-profile-service';

// Internal actions
import {setCspEnable, setPubSubMessageStatus} from "./slices/support";


const createRootReducer = (history: History<unknown>) => combineReducers({
    router: connectRouter(history),
    ruleStacks: ReduxReducers.ruleStacks,
    firewalls: ReduxReducers.firewalls,
    users: ReduxReducers.users,
    support: ReduxReducers.support,
    [firewallsService.reducerPath]: firewallsService.reducer,
    [regionsService.reducerPath]: regionsService.reducer,
    [userRolesService.reducerPath]: userRolesService.reducer,
    [rulesService.reducerPath]: rulesService.reducer,
    [accountsService.reducerPath]: accountsService.reducer,
    [logProfileService.reducerPath]: logProfileService.reducer,
    // rest of your reducers
});

const ReduxActionsWithInternalAction = {
    ...ReduxActions,
    setCspEnable,
    setPubSubMessageStatus
}

export {
    createRootReducer,
    ReduxResources,
    ReduxActionsWithInternalAction as ReduxActions,
    ReduxReducers
};
