import RaTranslationMessages from "./ra-translation-messages";

const englishMessages = {
    ...RaTranslationMessages,
    permissions: {
        cantView: "You don't have permission to view this section",
        cantExecute: "You don't have permission to execute this action."
    },
    validation: {
        invalidPassword: "Password does not meet the required criteria.",
        required: "This field is required",
        missMatch: "Passwords do not match",
        passwordRange: "8-99 Characters.",
        passwordOneUpper: "At least one uppercase letter.",
        passwordOneLower: "At least one lowercase letter.",
        passwordOneNumber: "At least one number.",
        passwordOneSpecial: "At least one special character from (^$*.[]{}()?\"!@#%&/><';:|_).",
        // eslint-disable-next-line
        passwordCriteria: `Password must be 8-99 characters. Requires: Number, Uppercase, Lowercase, and a Special Character from (^$*.[]{}()?"!@#%&/\,><':;|_~\``,
        ipV4Address: "Invalid IP v4 address",
        positiveInteger: "Invalid positive integer",
        fqdnList: "Invalid FQDNList (e.g. www.test.com, test2.com)",
        feedSourceUrl: "Invalid feed source url (e.g. http://www.test.com) ",
        domainNotSupported: "This email domain is not supported.",
        passwordContain: 'Password must contain:'
    },
    auth: {
        title: 'Create Tenant',
        password: 'Password',
        email: 'Email',
        name: 'First Name',
        lastName: 'Last Name',
        region: 'Region',
        button: 'Create',
        link: 'Already registered? Click here to Login',
        continue: 'Continue',
        linkAccountError: 'Account Id/Product ID/Argument ID Missing. Please go to AWS Marketplace and follow the instructions to set up your account!'
    },
    register: {
        successTitle: 'Create New Tenant Account request sent',
        successMessage: 'Check your email inbox for an invitation with temporary password.'
    },
    login: {
        name: 'Login',
        title: 'Login',
        email: 'Email',
        password: 'Password',
        button: 'Login',
        forgotPassword: 'Forgot password?',
        logIn: 'Log in'
    },
    generic: {
        yes: 'Yes',
        no: 'No',
        cancel: 'Cancel',
        save: 'Save',
        document: 'document',
        actions: 'Actions',
        delete: 'Delete',
        add: 'Add',
        edit: 'Edit',
        create: 'Create',
        tags: 'Tags',
        used: 'Used'
    },
    forgotPassword: {
        name: 'Forgot Password ?',
        title: 'Cloud NGFW Sevice',
        email: 'Email',
        button: 'Request Password Reset',
        successTitle: 'Password reset requested!',
        successMessage: 'The reset email can take up to 10 minutes to reach your inbox. Follow the instructions in the email'
    },
    confirmPassword: {
        name: 'Confirm Password',
        title: 'Welcome to Cloud NGFW service',
        email: 'Email',
        password: 'Password',
        verificationCode: 'Verification Code',
        button: 'Confirm',
        success: 'Password reset successful!'
    },
    changePassword: {
        name: 'Change Password',
        title: 'Change Password',
        email: 'Email',
        password: 'New Password',
        oldPassword: 'Current Password',
        reEnterPassword: 'Re-enter New Password',
        button: 'Change',
        success: 'Password change successful!'
    },
    setPassword: {
        name: 'Set Password',
        title: 'Check your email for a temporary password',
        email: 'Email',
        password: 'New Password',
        oldPassword: 'Temporary Password',
        reEnterPassword: 'Re-enter Password',
        button: 'Create',
        success: 'Password reset successful!'
    },
    switch: {
        enabled: "Enabled",
        disabled: "Disabled",
    },
    common: {
        edit: "Edit",
        view: 'View',
        actions: "Actions",
        delete: "Delete",
        cancel: "Cancel",
        confirm: "Confirm",
        back: "Back",
        close: "Close",
        next: "Next"
    },
    toolbar: {
        modal: {
            confirmDeleteTitle: "Confirm Delete",
            confirmDescription: "Are you sure that you want to delete this record?"
        }
    },
    grid: {
        loading: 'Loading data...',
    },
    chip: {
        add: 'Add',
        addNewTag: 'Add New Tag',
        key: 'Key',
        value: 'Value'
    },
    csp: {
        title: "Enable Palo Alto Networks Support",
        titleCongrats: "Congratulations!",
        subTitleCongrats: "Your Cloud NGFW tenant is ready to use.",
        startDetail: "Do you have a existing Palo Alto Networks support account?",
        confirmDetail: "This tenant and all users (current & future) will be mapped to this support account for support from Palo Alto Networks.",
        congratsDetail: "You now have Palo Alto Networks Premium Support for your Cloud NGFW tenant.",
        address: "ADDRESS",
        companyName: "Company Name",
        country: "Country",
        addressOne: "Address Line 1",
        addressOneSub: "Street address or P.O. Bo",
        addressTwo: "Address Line 2",
        addressTwoSub: "Apt. suite, unit, floor, etc.",
        city: "City",
        state: "State/Province/Region",
        zipCode: "Zip/Postal Code",
        descriptionDetail: "Your Cloud NGFW tenant is ready to use.",
        descriptionPremium: "Palo Alto Networks Premium Support",
        descriptionFinish: "You now have {descriptionPremium} for your Cloud NGFW tenant.",
        descriptionFinishWithNo: "To have {descriptionPremium} for your Cloud NGFW tenant. You need to register this tenant on Customer Support Portal",
        registerSupportAccount: "Register this tenant to Palo Alto Networks support account",
        registerCSP: "This tenant is not registered to the Palo Alto Networks support account. Please register this tenant in the Customer Support Portal (CSP) using the Tenant ID and Serial Number on the Tenant page.",
        registerCSPBanner: "This tenant is not registered to the Palo Alto Networks support account. To  use any Advanced Services, link to Policy managers such as Panorama or get Customer support, please register Cloud NGFW to Customer Support Portal",
        goToCSP: "Register Your Tenant on CSP",
        toastForSerialNumber: "Serial Number generation is in progress, it will be auto populated on the Tenant page momentarily. Once completed you can register to Customer support portal registration",
        waitForSupportAccount: "Your Customer Support Registration is in progress and should be ready momentarily. Refresh page in few min to verify Support account is populated on Tenant page"
    },
    cspOld: {
        title: "Enable Palo Alto Networks Standard Support",
        titleEnd: "Your Cloud NGFW tenant is ready to use.",
        startDetail: "Do you want to use an existing Palo Alto Networks support account?",
        address: "ADDRESS",
        companyName: "Company Name",
        country: "Country",
        addressOne: "Address Line 1",
        addressOneSub: "Street address or P.O. Box",
        addressTwo: "Address Line 2",
        addressTwoSub: "Apt. suite, unit, floor, etc.",
        city: "City",
        state: "State/Province/Region",
        zipCode: "Zip/Postal Code",
        descriptionFinish: "Palo Alto Networks will establish Premium Support for your Cloud NGFW tenant in the next 24 hours.",
        btnNext: "Next",
        btnClose: "Close",
    },
    linkTenant: {
        title: "Welcome to Cloud NGFW",
        existingTenant: "Login with an Existing Tenant",
        newTenantTitle: "New to Palo Alto Networks Cloud NGFW ?",
        newTenant: "Create a New Tenant",
        message: "We're in the process of connecting your AWS account to the Cloud NGFW tenant. This may take up to a minute to complete."
    },
    linkTenantSuccessful: {
        title: "Congratulations!",
        subTitle: "To complete your subscription on AWS Marketplace, please click the 'Continue to Finish Subscription' button below",
        finishSubscription: "Continue to Finish Subscription",
    },
    activate: {
        title: "Welcome to Cloud NGFW",
        message: "An activation email has been sent to you. Please check your email, activate the account and click on the 'Login' button below. {break} If you do not receive email please visit {link}.",
        login: "Login",
        getHelp: "Get Help"
    },
    panwdsTable: {
        confirmDelete: 'Confirm Delete',
        sureDelete: 'Are you sure that you want to delete this record?',
        confirm: 'Confirm'
    },
    panwdsTabTable: {
        notification: 'Notification',
        confirmMessage: 'Are you sure you want to move the following VPC(s) to',
        lastUpdated: 'Last Updated',
    },
    multiTenantModal: {
        title: "Select a Tenant",
        submit: "Continue",
        noTenantError: "User is not authorized to access this service"

    },
    oktaRegistrationModal: {
        title: "Register to Palo Alto Networks SSO",
        activeTitle: "Enable the Palo Alto Networks SSO",
        body: {
            title: "To enable Multi Factor Athentication, please register to Palo Alto Networks Single Sign-On (SSO)",
            activeTitle: "You have successfully completed the registration workflow. Please enable the Palo Alto Networks SSO and log back in.",
            provisionedTitle: "You have not completed the Palo Alto Networks Single Sign-On (SSO) registration.",
            provisionedOktaTitle: "Please ensure that you check your email and follow the instructions provided in order to successfully complete the registration process.",
            createdTitle: "The email has been sent successfully",
            content: "Once you click “Continue”, you will receive an email with instructions on how to complete the registration process.",
            provisionedContent: "Please ensure that you check your email and follow the instructions provided in order to successfully complete the registration process. {break} If you do not receive email please visit {link}.",
            activeContent: "To experience seamless navigation and enhanced security, click on the 'Enable and Log Out' button to enable the Palo Alto Networks SSO and log back in via Palo Alto Networks SSO to enjoy uninterrupted access to our services.",
            createdContent: "You will receive email shortly. Please ensure that you check your email and follow the instruction provided in order to successfully complete the registration process. {break} If you do not receive email please visit {link}.",
            emailSent: "Email sent successfully!",
            getHelp: "Get Help"
        },
        submit: "Continue",
        registerLater: "Register Later",
        resendEmail: "Resend Email",
        activeSubmit: "Enable and Log Out"
    },
    resources: {
        ruleStacks: {
            name: 'Rulestacks',
            subtitle: 'A Rulestack is a set of security rules, and associated objects and security services, used for enabling advanced access control (APP-ID&trade;, URL filtering) and threat prevention features. A Rulestack can be associated with one or more Firewalls. You can create two types of rulestacks - global and local. Global rulestacks apply to all firewalls in your deployment and local rulestacks apply to specific firewalls.',
            fields: {
                Create: {
                    Local: 'Create Local Rulestack',
                    Global: 'Create Global Rulestack'
                },
                General: 'General',
                RulestackName: 'Name',
                RuleStackState: 'Status',
                Scope: 'Type',
                AccountId: 'Account Id',
                XFF: 'Enable X-Forwarded-For for Security Policy',
                XFFLabel: 'Cloud NGFW for AWS can use the IP address in the X-Forwarded-For (XFF) field of the HTTP header to enforce security policy.',
                AssociatedFirewalls: 'Associated Firewalls',
                Tags: 'Tags',
                Description: 'Description',
                CreateButton: 'Create Rulestack',
                EmptyTitle: 'You have not created Rulestack.',
                EmptySubtitle: 'Create a Rulestack to associate with a Firewall.',
                ListSubtitle: 'Rulestacks define the NGFW traffic filtering behavior such as advanced access control (App-ID, URL Filtering) and threat prevention. A rulestack includes a set of security rules and the associated objects and security profiles. To use a rulestack, you associate it with one or more NGFW resources.',
                Action: 'Action'

            },
            overviewPanel: {
                rulestackId: 'Rulestack ID',
                lastDeployment: 'Last Deployment',
                rules: 'Rules',
                associatedFirewalls: 'Associated Firewalls'
            },
            commit: {
                progress: "Rulestack {rulestackName} commit is in progress.",
                success: "Rulestack {rulestackName} committed successfully!"
            },
            validate: {
                progress: "Rulestack {rulestackName} validate is in progress.",
                success: "Rulestack {rulestackName} validated successfully!"
            },
            revert: {
                progress: "Rulestack {rulestackName} revert is in progress.",
                success: "Rulestack {rulestackName} reverted successfully!"
            },
            update: {
                success: "Rulestack {rulestackName} updated successfully",
            },
            diff: {
                title: "Config Diff of Running Config and Candidate Config",
                running: "Running Config",
                candidate: "Candidate Config",
            },
            actions: {
                deploy: "Deploy Configuration",
                validate: "Validate Configuration",
                revert: "Revert to Last Deployed",
                lastStatus: "Last Deployment Status",
                deploying: "Deploying",
                validating: "Validating",
                reverting: "Reverting",
                fetching: "Fetching Status",
                configActions: "Config Actions",
            },
            modal: {
                cancel: "Cancel",
                validate: "Validate Commit",
                commit: "Commit",
                revert: "Revert",
                deployConf: "Deploy Configuration",
                validateConf: "Validate Configuration",
                revertConf: "Revert to Last Deployed",
                tableTitle: "Associated Firewall(s) to this RuleStack",
                notFound: "No associated firewall(s) found.",
                deployDescription: "Click commit to push your configured rulestack to your firewalls.",
                validateDescription: "Click Validate Commit to push your configured rulestack to your firewalls.",
                revertDescription: "This operation will revert to the last commit. Are you sure?",

            },
            profile: "Profile",
            securityService: {
                options: {
                    bestPractice: "Best Practice",
                    disable: "Disable",
                    custom: "Custom"
                },
                IPSandSpyware: {
                    title: "IPS and Spyware Threats Protection",
                    subtitle: "IPS vulnerability and anti-spyware protect your network against attacks that exploit system flaws and remote attacks such as command-and-control activity.",
                    fields: {
                        VulnerabilityProfile: {
                            title: "IPS Vulnerability",
                            content: "An Intrusion Protection System (IPS) is a network security and threat prevention technology that examines traffic flows to detect and prevent vulnerability exploits."
                        },
                        AntiSpywareProfile: {
                            title: "Anti-Spyware",
                            content: "Anti-spyware protection zeroes in outbound threats, especially command-and-control (C2) activity, where an infected client is being leveraged as part of a remotely-conducted cyber attack."
                        }
                    }
                },
                MalwareAndFileBase: {
                    title: "File Blocking & URL Categories & Filtering",
                    subtitle: "Use Malware and File-based threat to protect against malware concealed in files, executables, and email links.",
                    fields: {
                        AntivirusProfile: {
                            title: "Antivirus",
                            content: "Antivirus protects against viruses, worms, and trojans as well as spyware downloads."
                        },
                        WildfireProfile: {
                            title: "Wildfire",
                            content: "Wildfire identifies previously unknown malware and generates signatures that Palo Alto Networks firewalls can use to then detect and block the malware",
                        },
                        FileBlockingProfile: {
                            title: "File Blocking",
                            content: "Use file blocking to prevent the transmission of specific file types sent over your network.",
                        }
                    }
                },
                WebBasedThreat: {
                    title: "Web based Threat Protection",
                    subtitle: "Web-based threat protection control users' access to and activity on the web.",
                    fields: {
                        URLFilteringProfile: {
                            title: "URL Categories and Filtering",
                            content: "Control users’ access to web content, and how they interact with it (for example, to prevent phishing, block users from submitting corporate credentials to non-corporate sites). Also enforce safe search for search engines like Google and Bing."
                        },
                    }
                },
                EncryptedThreat: {
                    title: "Encrypted Threat Protection",
                    subtitle: "Prevent threats to your network cloaked within Secure Sockets Layer (SSL) traffic.",
                    fields: {
                        OutboundDecryptionProfile: {
                            title: "Outbound Decryption",
                            content: "The firewall decrypts SSL traffic leaving your network to prevent exfiltration of sensetive information."
                        },
                    }
                },
                threatPrevention: "Threat Prevention",
                warning: "Warning",
                disableATP: "Are you sure you want to disable the Advanced Threats Protection (ATP)",
                enableATP: "Are you sure you want to enable the Advanced Threats Protection (ATP)",
                disableATPSubtitle: "Please note that you can always re-enable it after disabling it. To learn more about the benefits and advantages of Advanced Threat Protection, make sure to check out this ",
                enableATPSubtitle: "Please note that enabling this feature may result in changes being applied. To learn more about the benefits and advantages of Advanced Threat Protection, make sure to check out this ",
                advancedThreatPreventionEnabled: "Advanced Threat Prevention Enabled",
                advancedThreatPreventionEnabledSubtitle: "To use Advanced Threats Prevention, you need to enable this option. For more detailed information, please refer to the ",
                document: "document"
            }
        },
        firewalls: {
            name: 'NGFWs',
            create: {
                title: 'Create Firewall',
                selectAccount: 'Please select an Account.',
            },
            fields: {
                General: 'General',
                FirewallName: 'Name',
                FirewallId: 'ID',
                Status: 'Status',
                Endpoints: 'Endpoints',
                AccountId: 'AWS Account ID',
                Tags: 'Tags',
                Description: 'Description',
                VpcId: 'VPC',
                Rulestacks: 'Rulestacks',
                Shared: 'Do you want to share your NGFW with other VPCs?',
                SharedTooltip: 'Sharing NGFW allows VPC endpoint creation for NGFW in multiple VPCs',
                RuleStackName: 'Local Rulestack',
                LogDestination: 'Log Destination',
                LogDestinationSubtitle: 'Please configure the log destination below if your firewall is not associated with a Strata Logging Service (SLS)',
                LogDestinationType: 'Log Destination Type',
                LogType: 'Log Type',
                Logs: 'Logs',
                Metrics: 'Metrics',
                LogGeneration: 'Log Generation',
                LogGenerationSubtitle: 'Configure the Log Generation to receive additional log information',
                AdditionalThreatLogs: 'Additional Threat Logs',
                AdditionalThreatLogsHelpText: 'Enable this option to generate additional threat logs in your Strata Logging Service and/or AWS Threat Destination (must be configured in Log Destination section above)',
                Details: 'Details',
                EndpointId: 'Endpoint Id',
                EndpointStatus: 'Endpoint Status',
                EgressNAT: {
                    Title: 'Egress NAT',
                    EnableEgressNat: 'Enable Egress NAT',
                    EgressNatEnabled: "Egress NAT Enabled",
                    EnableEgressNatMutedText: 'Enabling Egress NAT allows for the use of public IPs from AWS Services or your own IPs. In the Endpoint Management section, you can manage Egress NAT for each endpoint.',
                    AWSService: 'AWS Service IPs',
                    BYOIP: 'Bring Your Public IPs',
                    IPPoolTypeBannerText: 'Please be aware that altering this option may lead to traffic disruptions for few seconds.',
                    PrivateAndPublicTraffic: 'Private & Public Traffic Addresses',
                    ManageTrafficAndEgressNat: 'Manage Traffic and Egress NAT',
                    PerformEgressNAT: 'Perform Egress NAT',
                    PerformEgressNATMutedText: 'Perform Egress Network Address Translation (Egress NAT) to all destination public IP addresses except to the destinations defined in the Private Traffic Range above.',
                    PrivateTrafficRange: 'Private Traffic Range',
                    IanaRfc: 'IANA RFC 1918',
                    IanaRfcMutedText: 'By default, IANA RFC 1918 are automatically included in Prefixes for Private Traffic.',
                    AddAdditionalPrefixes: 'Add Additional Prefixes To Private Traffic Range',
                    AddAdditionalPrefixesMutedText: 'If your organization uses public IP ranges in VPC and On-premises , you will need to specify those IP prefixes explicitly. The public IP prefixes can be specified individually or as aggregates.',
                    AdditionalPrefixes: 'Additional Prefixes',
                    AdditionalPrefixesMutedText: 'You can add multiple prefixes using a comma to separate.',
                },
                SubnetId: 'Subnet Id',
                EndpointServiceName: 'VPC Endpoint Service Name',
                CloudWatchNamespace: 'CloudWatch Namespace',
                CloudWatchMetricsFields: 'CloudWatch Metrics',
                PolicyManagement: 'Policy Management',
                PolicyManagementLinkIdMessage: 'To manage policy by Rulestack, please disassociate Panorama by selecting "None" option below',
                PolicyManagementRSMessage: 'To manage policy by Panorama, please disassociate Rulestack by selecting "None" option below',
                PolicyManagementSCMMessage: 'If you would like to switch to a different policy manager, kindly {link}, or you can create a new firewall.',
                PrivateAccess: 'Private Access',
                UserIdRedistributionClient: 'User ID Redistribution Client (Preview)',
                EnableAWSNetworkLoadBalanacer: 'Enable AWS Network Load balancer',
                EnableAWSNetworkLoadBalancerHelpText: 'Distributing your incoming traffic across multiple targets, such as EC2 instances, containers, and IP addresses, in one or more Availability Zones. To learn more details, please check this {document}',
                AmazonResourceARN: 'VPC Endpoint Service Name',
                AmazonResourceARNHelpText: 'To create the VPC Endpoint Service, please {downloadCFT} or {launchCFT}. Once created in your AWS account, you can paste the name here.',
                AmazonResourceARNTooltip: 'A VPC Endpoint Service, enabled by AWS PrivateLink, is a feature of Amazon Web Services (AWS) that facilitates private connections between Virtual Private Clouds (VPCs) and AWS services. It allows secure access to services within a VPC without using the public internet. An endpoint service name is given when you create this service in your AWS account, which is then used to set up private connections to the resources in your account by other AWS account holders.',
                downloadCFT: 'Download Cloud Formation Template',
                launchCFT: 'Launch Cloud Formation Template in AWS Portal',
                SourceName: 'Source Name',
                SourceNameHelpText: 'By default, the system provides a unique name to help you to identify the User ID logs in Panorama. However you can provide a unique name.',
                SourceNameTooltip: 'Source Name refers to the identifier assigned to a specific source of user mapping information. It differentiates and identifies the specific user mapping source within the user-ID configuration.',
                EnableUserID: 'Enable User ID Redistribution Client { status }',
                EnableUserIDHelpText: 'To ensure all the firewalls that enforce policies and generate reports have the required IP address-to-username mappings. To learn more details, please check this {document}',
                Port: 'Port',
                Delete: 'Delete',
                Discovery: 'Discovery',
                CustomIncludeExcludeNetworkDescription: 'This is an optional field. Map containing network Subnet Include/Exclude rules to be applied in order',
                PortHelpText: 'By default, the port is 5007. However, you can use another single port to replace it.',
                CollectorName: 'Collector Name',
                CollectorNameHelpText: 'To learn where to get the Collector Name, please check this {document}',
                AWSSecretKeyARN: 'Pre-Shared Key (AWS Secret ARN)',
                AWSSecretKeyARNHelpText: 'To learn more about Pre-Shared Key (AWS Secret ARN), please check this {document}',
                AddIncludeExcludeRule: 'Add Include/Exclude Rule',
                AddCustomIncludeExcludeNetwork: 'Add Custom Include/Exclude Network',
                CustomIncludeExcludeNetwork: 'Custom Include/Exclude Network',
                CustomIncludeExcludeNetworkEnabled: 'Enabled',
                CustomIncludeExcludeNetworkEnabledHelpText: 'Select this option to enable inclusion or exclusion of the subnetwork for server monitoring.',
                CustomIncludeExcludeNetworkEnabledHeader: 'Enabled Inclusion or Exclusion',
                CustomIncludeExcludeNetworkName: 'Name',
                CustomIncludeExcludeNetworkNetworkAddress: 'Network Address',
                CustomIncludeExcludeNetworkDiscoveryInclude: 'Discovery',
                CustomIncludeExcludeNetworkEmptyTitle: `You haven't set up network subnet Include/Exclude rules`,
                CustomIncludeExcludeNetworkEmptySubTitle: `By default, if you don't add any subnetworks to the list, the User-ID agent performs discovery for user identification sources in all subnetworks except when using WMI probing for client systems that have public IPv4 addresses`,
                CreateANewCase: 'Create a New Case',
                LocalRulestack: 'Local Rulestack',
                Panorama: 'Panorama',
                StrataCloudManager: 'Strata Cloud Manager',
                NoStrataCloudManagerLink: 'Link-SCM-None',
                RuleStackSubtitle: 'Select a Local Rulestack for this Firewall',
                LinkedPanorama: 'Linked Panorama',
                PolicyManager: 'Policy Manager',
                LinkedCloudManager: 'Linked Strata Cloud Manager',
                LinkedPanoramaMessage: 'To disassociate or to link to a different Panorama, please go to the associated Panorama above and disassociate the Cloud Device Group with the current Firewall',
                LinkedCloudManagerMessage: 'Kindly be informed that if you wish to make any modifications after creating, it is necessary to disassociate the Strata Cloud Manager before proceeding with the changes.',
                EndpointManagement: 'Endpoint Management',
                EndpointManagementSubtitle: 'You can enable this NGFW to secure traffic in multiple AWS availability zones. You pay Cloud NGFW for each AWS availability zone the NGFW is provisioned to secure traffic.',
                AvailabilityZone: 'Availability Zone',
                Subnet: 'Subnet',
                SubnetSubtitle: 'Do you want Cloud NGFW to create endpoints automatically on your VPC subnets?',
                CreateButton: 'Create Firewall',
                EmptyTitle: 'You have not created a Next Generation firewall.',
                EmptySubtitle: 'Press Create Firewall to start creating a firewall.',
                ListSubtitle: 'This table displays your current Next Generation firewalls in the selected region. Select a firewall to edit the configuration.',
                firewallDeletionMessage: 'Firewall deletion is in progress:',
                firewallDeletionErrorMessage: "Couldn't delete the following Firewall(s):",
                FirewallNotSavedBannerText: "Your firewall changes have not been saved. To ensure the changes take effect, please click the \"Save\" button at the bottom of the page.",
                FirewallUpdatingBannerText: "This firewall is currently being updated. It may take a few minutes. During this time, no changes can be made.",
                FirewallUpdateSuccessBannerText: "The firewall has been updated successfully. You can now make any changes to this firewall.",
                FirewallUpdateFailedBannerText: "Firewall update failed. Please try again.",
            },
            overviewPanel: {
                firewallId: 'Firewall ID',
                awsAccountId: 'AWS Account ID',
                endpointMode: 'EndpointMode',
                status: 'Status',
                rulestacks: 'RuleStacks',
                awsConsoleLinks: 'AWS Console Links',
                checkDetails: 'Check Details',
                rs1Global: 'RS1(Global)',
                rs1Local: 'RS1(Local)',
                logMetric: 'Logs & Metrics',
                review: 'Review',
                needsReview: 'Needs Review',
                endpoints: 'Endpoints',
                strataCloudManagerAssociation: 'Strata Cloud Manager Association'
            },
            modal: {
                statuses: 'Statuses',
                close: 'Close',
                firewallStatus: 'Firewall Status',
                firewallCommitStatus: 'Firewall Commit Status',
                deviceRuleStackCommitStatus: 'Device RuleStack Commit Status',
                localRulestackStatus: 'Local Rulestack Status',
                globalRulestackStatus: 'Global Rulestack Status',
                endpointStatus: 'Endpoint Status',
                disassociateModalTitle: "Confirm Rulestack Disassociation",
                disassociateModalDescription: "Cloud NFGW resource is not associated with any rules(tack). The resource will drop all traffic directed through it.",
                confirm: "Confirm",
                scmConfirm: "Are you sure you want to disassociate the current firewall from the Strata Cloud Manager? It is important to note that after making changes to the firewall, the firewall settings may take a few minutes to update. During this time, it is not possible to make any further changes to this firewall.",
                warning: "Warning",
                submitModalBodyTitle: "Are you sure you want to make the change for this firewall",
                submitModalBodyDesc: "It is important to note that after making changes to the firewall, the firewall settings may take a few minutes to update. During this time, it is not possible to make any further changes to this firewall.",
            },
            dropdown: {
                logsThreat: 'Logs: Threat',
                logsTraffic: 'Logs: Traffic',
                logsDecryption: 'Logs: Decryption',
                metrics: 'Metrics',
                endpointServiceARN: 'Endpoint Service ARN'
            }
        },
        firewallsV2: {
            title: 'Next Generation Firewalls (NGFWs)',
            shortTitle: 'NGFWs',
            create: 'Create Firewall',
            delete: 'Delete Firewall',
            firewallName: 'Firewall Name',
            firewallStatus: 'Firewall Status',
            status: 'Status',
            firewallId: 'Firewall ID',
            endpoints: 'Endpoints',
            emptyFirewallsTable: 'No Firewall Created',
            region: 'Region',
            generalInfo: 'General Info',
            availabilityZoneIDs: 'Availability Zone IDs',
            deleteProtection: 'Delete Protection',
            securityPolicies: 'Security Policies',
            endpointManagement: 'Endpoint Management',
            localRulestack: 'Local Rulestack',
            localRulestackStatus: 'Local Rulestack Status',
            policyManager: 'Policy Manager',
            linkedPanorama: 'Linked Panorama',
            rulestack: 'Rulestack',
            panorama: 'Panorama',
            strataCloudManager: 'Strata Cloud Manager',
            defaultRulestack: 'Default Rulestack',
            allowListedAccounts: 'Allowlisted AWS Accounts',
            linkedCloudManagerMessage: 'Kindly be informed that if you wish to make any modifications after creating, it is necessary to disassociate the Strata Cloud Manager before proceeding with the changes.',
            linkedPanoramaMessage: 'To disassociate or to link to a different Panorama, please go to the associated Panorama above and disassociate the Cloud Device Group with the current Firewall',
            allowListedAccountsMessage: 'To successfully whitelist the specified AWS accounts, please ensure you enter the correct account number',
            globalFirewallAdmin: 'Global Firewall Admin',
            overview: 'Overview',
            numberOfRules: 'Number Of Rules',
            managedBy: 'Managed By',
            enabled: 'Enabled',
            publicIPType: 'Public IP Type',
            publicIPs: 'Public IPs',
            userIdStatus: 'User ID Status',
            vpcEndpointService: 'VPC Endpoint Service',
            port: 'Port',
            sourceName: 'Source Name',
            egressNat: 'Egress NAT',
            userId: 'User ID',
            numberOfEndpoints: 'Number of Endpoints',
            endpointsStatus: 'Endpoint Status',
            logDestinationType: 'Log Destination Type',
            logType: 'Log Type',
            policyType: 'Policy Type',
            logDestination: 'Log Destination',
            rules: "Rules",
            logAndMetrics: "Logs & Metrics",
            decryption: "Decryption",
            description: "Description",
            endpointServiceName: "VPC Endpoint Service Name",
            regionEditDescription: "Region can not be changed once firewall is created.",
            firewallIdEditDescription: "This is system generated the value for firewall. It can not be changed.",
            firewallNameEditDescription: "This is an optional field. You can specify a custom name for this firewall.",
            userid: {
                sourceName: 'Source Name',
                cloudWatchNamespace: 'CloudWatch Namespace',
                cloudWatchMetricsFields: 'CloudWatch Metrics',
                policyManagement: 'Policy Management',
                policyManagementLinkIdMessage: 'To manage policy by Rulestack, please disassociate Panorama by selecting "None" option below',
                policyManagementRSMessage: 'To manage policy by Panorama, please disassociate Rulestack by selecting "None" option below',
                policyManagementSCMMessage: 'If you would like to switch to a different policy manager, kindly {link}, or you can create a new firewall.',
                privateAccess: 'Private Access',
                userIdRedistributionClient: 'User ID Redistribution Client (Preview)',
                enableAWSNetworkLoadBalanacer: 'Enable AWS Network Load balancer',
                enableAWSNetworkLoadBalancerHelpText: 'Distributing your incoming traffic across multiple targets, such as EC2 instances, containers, and IP addresses, in one or more Availability Zones. To learn more details, please check this {document}',
                amazonResourceARN: 'VPC Endpoint Service Name',
                amazonResourceARNHelpText: 'To create the VPC Endpoint Service, please {downloadCFT} or {launchCFT}. Once created in your AWS account, you can paste the name here.',
                amazonResourceARNTooltip: 'A VPC Endpoint Service, enabled by AWS PrivateLink, is a feature of Amazon Web Services (AWS) that facilitates private connections between Virtual Private Clouds (VPCs) and AWS services. It allows secure access to services within a VPC without using the public internet. An endpoint service name is given when you create this service in your AWS account, which is then used to set up private connections to the resources in your account by other AWS account holders.',
                downloadCFT: 'Download Cloud Formation Template',
                launchCFT: 'Launch Cloud Formation Template in AWS Portal',
                dourceName: 'Source Name',
                sourceNameHelpText: 'By default, the system provides a unique name to help you to identify the User ID logs in Panorama. However you can provide a unique name.',
                sourceNameTooltip: 'Source Name refers to the identifier assigned to a specific source of user mapping information. It differentiates and identifies the specific user mapping source within the user-ID configuration.',
                enableUserID: 'Enable User ID Redistribution Client',
                enableUserIDHelpText: 'To ensure all the firewalls that enforce policies and generate reports have the required IP address-to-username mappings. To learn more details, please check this {document}',
                port: 'Port',
                discovery: 'Discovery',
                include: 'Include',
                exclude: 'Exclude',
                customIncludeExcludeNetworkDescription: 'This is an optional field. Map containing network Subnet Include/Exclude rules to be applied in order',
                portHelpText: 'By default, the port is 5007. However, you can use another single port to replace it.',
                collectorName: 'Collector Name',
                collectorNameHelpText: 'To learn where to get the Collector Name, please check this {document}',
                awsSecretKeyARN: 'Pre-Shared Key (AWS Secret ARN)',
                awsSecretKeyARNHelpText: 'To learn more about Pre-Shared Key (AWS Secret ARN), please check this {document}',
                addIncludeExcludeRule: 'Add Include/Exclude Rule',
                addCustomIncludeExcludeNetwork: 'Add Custom Include/Exclude Network',
                editCustomIncludeExcludeNetwork: 'Edit Custom Include/Exclude Network',
                customIncludeExcludeNetwork: 'Custom Include/Exclude Network',
                customIncludeExcludeNetworkEnabled: 'Enabled',
                customIncludeExcludeNetworkEnabledHelpText: 'Select this option to enable inclusion or exclusion of the subnetwork for server monitoring.',
                customIncludeExcludeNetworkEnabledHeader: 'Enabled Inclusion or Exclusion',
                customIncludeExcludeNetworkName: 'Name',
                customIncludeExcludeNetworkNetworkAddress: 'Network Address',
                customIncludeExcludeNetworkDiscoveryInclude: 'Discovery',
                customIncludeExcludeNetworkEmptyTitle: `You haven't set up network subnet Include/Exclude rules`,
                customIncludeExcludeNetworkEmptySubTitle: `By default, if you don't add any subnetworks to the list, the User-ID agent performs discovery for user identification sources in all subnetworks except when using WMI probing for client systems that have public IPv4 addresses`,
                successBannerMsg: 'User ID is enabled successfully',
                errorBannerMsg: 'Something went wrong during the configuration. Please try again later. If you are still having the issue, please contact the support team.',
                pendingBannerMsg: 'User ID enabling is currently in progress. It may take upto a minute to complete.'
            },
            enableEgressNat: 'Enable Egress NAT',
            egressNatEnabled: "Egress NAT Enabled",
            enableEgressNatMutedText: 'Egress NAT is used for translating a private IP address to a public routable address by changing the source address of the packets that pass through the Cloud NGFW. Egress NAT allows connections to be initiated only for outgoing network connections.',
            awsService: 'AWS Service IPs',
            byoip: 'Bring Your Public IPs',
            ipAddressPool: 'IP Address Pool',
            IPPoolTypeBannerText: 'Please be aware that altering this option may lead to traffic disruptions for few seconds.',
            firewallEndpointServiceNameEditDescription: "This is the Cloud NGFW VPC Endpoint Service Name, which helps you connect to the Cloud NGFW resource {learnMore}",
            firewallAllowlistedAwsAccountsEditDescription: "To successfully whitelist the AWS accounts you specified, please ensure that you have entered the correct account numbers.",
            addEndpoint: "Add Endpoint",
            editEndpoint: "Edit Endpoint",
            subnetId: "Subnet ID",
            vpcId: "VPC ID",
            endpointId: "Endpoint ID",
            linkedAwsAccounts: "Linked AWS Accounts",
            vpcSelectPlaceholder: "Select a VPC ID",
            subnetSelectPlaceholder: "Select a Subnet ID",
            linkedAwsAccountsPlaceholder: "Select one of the onboarded AWS Accounts",
            endpointPrivatePrefix: "Prefix for Private Traffic",
            endpointPublicPrefix: "Prefix for Public Traffic",
            deleteFirewallConfirmationTitle: "Delete Firewall",
            deleteFirewallMessage: "Are you sure you want to delete this Firewall?",
            logMetrics: {
                title: "Log Destination Options",
                subTitle: "Please configure the log destination below if your firewall is not associated with a Cortex Data Lake (CDL)",
                logDestinationType: "Log Destination Type",
                logType: "Log Type",
                logTypeDescription: "You can select one or more log types for Traffic, Threat, Decryption and User ID.",
                s3: 'S3',
                s3Tooltip: "Amazon Simple Storage Service (Amazon S3) is an object storage service offered by Amazon Web Services (AWS). It is designed to store and retrieve any amount of data from anywhere on the web.",
                cloudwatchLogGroup: 'CloudWatch Log Group',
                cloudWatchTooltip: "Amazon CloudWatch Log Group is a logical grouping of log streams in Amazon CloudWatch Logs, a service provided by AWS for monitoring and storing log data. Log groups allow you to organize and manage log data generated by your applications, systems, and AWS services.",
                kinesisDataFirehose: 'Kinesis Data Firehose',
                kinesisTooltip: "Amazon Kinesis Data Firehose is a fully managed service provided by AWS that reliably loads streaming data into data lakes, data stores, and analytics services. It is designed to capture, transform, and deliver streaming data to destinations such as Amazon S3, Amazon Redshift, Amazon Elasticsearch Service, and Splunk, in real-time.",
                policyType: "Policy Type",
                resourceBasedPolicy: "Resource Based Policy",
                iamBasedPolicy: "IAM Based Policy",
                roleBasedTooltip: "The resource-based policy is a type of policy in AWS that you attach directly to a resource such as an S3 bucket or KMS key. The resource-based policy allows you to specify who (which AWS accounts or services) can access and perform actions on the log destination resource, such as an S3 bucket.",
                iamBasedTooltip: "An IAM-based policy for Cloud NGFW log destination involves creating and attaching policies to IAM roles or users that specify the permissions required for the Cloud NGFW service to write logs to a designated AWS resource, such as an S3 bucket. ",
                linkedAccountTooltip: "An AWS account that has been granted specific permissions to interact with or manage resources. Cloud NGFW requires permissions to access information and resources associated with your AWS accounts.  The IAM role created in your account provides Cloud NGFW with the permissions necessary to read VPC information required to create and manage endpoints, send logs to logging destinations, and access certificates in the AWS Secrets Manager for traffic decryption.",
                logDestination: "Log Destination",
                traffic: "Traffic",
                threat: "Threat",
                decryption: "Decryption",
                userId: "User ID",
                logGenerationOptions: "Log Generation Options",
                advancedThreatLog: "Advanced Threat Log Generation",
                advancedThreatLogDescription: "Enable this option to generate additional threat logs in your Cortex Data Lake and AWS destination (Must be configured in Log Destination section).",
                metrics: "Metrics",
                enableCloudWatchMetrics: "Enable CloudWatch Metrics",
                enableCloudWatchMetricsDescription: "To enable this feature, you must have at least one linked AWS account. Please link your AWS account first, if you do not have one.",
                cloudWatchNamespace: "CloudWatch Namespace",
                cloudWatchNamespaceTooltip: "In Amazon CloudWatch, a namespace is a container for CloudWatch metrics. Namespaces are used to group related metrics together so that they can be organized and managed more effectively.",
                cloudWatchMetrics: "CloudWatch Metrics",
                cloudWatchMetricsTooltip: "Amazon CloudWatch Metrics are numerical data points that provide insights into the performance and health of your AWS resources and applications.",
                logProfileUpdateSuccess: "Log profile update successful.",
            }
        },
        overviewPanel: {
            na: 'N/A'
        },
        knowledgeCenter: {
            createACase: 'Create a case',
            reportAnOutage: 'Report an outage',
            cloudNgfwVideoPlaylist:'Cloud NGFW Video Playlist',
            getTrainedInNgfw: 'Get trained in Cloud NGFW',
            cloudNgfwLiveCommunity: 'Cloud NGFW Live Community',
            viewStatusPage: 'View Status Page',
            apiDocumentation: 'API Documentation'
        },
        users: {
            name: 'Users and Roles',
            fields: {
                Name: 'Name',
                FirstName: 'FirstName',
                LastName: 'LastName',
                UserName: 'Email',
                UserStatus: 'Status',
                ChangePassword: 'Change Password',
                Roles: ' Roles',
                CreateButton: 'Invite User',
                ListSubtitle: 'As a Tenant Administrator you can invite users in your email domain to use the tenant. Click a user name to add or remove previously defined AWS roles. You can deactivate an account and reactivate it later, or delete it.',
                EmptyTitle: 'You have not created a User.',
                EmptySubtitle: 'Press Invite User to start creating a firewall.',
                SelfDelete: 'Cannot delete logged in user!',
                Cancel: 'Cancel',
                Save: 'Save',
                Submit: 'Submit',
                Create: 'Create',
                StatusPending: 'PENDING',
                StatusPendingMessage: 'This user account is awaiting email address activation. Please check if the user followed the activation email to complete the registration process.'
            },
        },
        inventory: {
            name: 'Inventory',
            close: 'Close',
            vpcs: 'VPCs',
            cancel: 'Cancel',
            emptyVpcsSubtitle: 'There Are Not Any Discovered VPCs',
            emptyTagsSubtitle: 'There Are Not Any Discovered Tags',
            enableRegion: 'Enable Region',
            regionAccessTooltip: 'Please make sure that this page is enabled to access data for the chosen region. To effectively monitor the account, it is necessary to have the Network Monitoring Role set up on the AWS Accounts page and TenantAdmin permission for the logged user. Please ensure that this role and permissions are established. ',
            vpc: {
              vpc: 'VPC',
              awsaccount: 'AWS Account ID',
              vpcMonitoring: 'Monitoring Status',
              status: 'Status',
              vpcGroup: 'VPC Group',
              manageVPCs: 'Manage VPCs',
              manageVPCGroups: 'Manage VPC Groups',
              refresh: 'Refresh',
              discoveredVPCs: 'Discovered VPCs',
              setVPCGroup: 'Set VPC Group'
            },
            tags: {
              tagName: 'Tag Name',
              discoveredTags: 'Discovered Tags',
              checkAssociatedTags: 'Check Associated Tags',
              selectedIPAddress: 'Selected IP Address',
              associatedTags: 'Associated Tags',
              noassociatedTags: 'No Associated Tags',
              back: 'Back'
            },
            tagIPs: {
              ipAddress: 'IP Address',
              action: 'Action',
              checkAssociatedTags: 'Check Associated Tags',
              tagDetails: 'Tag Details',
              associatedIpAddresses: 'Associated IP Addresses',
              noassociatedIpAddresses: 'No associated IP addresses'
            },
            vpcGroups: {
              manageVpcGroup: 'Manage VPC Group',
              vpcGroups: 'VPC Groups',
              vpcGroupName: 'VPC Group Name',
              description: 'Description',
              noOfVPCscount: 'Number of VPCs',
              actions: 'Actions',
              edit: 'Edit',
              delete: 'Delete',
              createNewVPCGroup: 'Create New VPC Group',
              editVpcGroup: 'Edit VPC Group',
              deleteGroupMessage: 'Before deleting',
              deleteSubGroupMessage: 'please note that any VPCs within it will be transferred to the "Default Group". Would you like to proceed with deleting',
              noVpcGroups: 'No VPC groups'
            }
        },
        overview: {
            name: 'Overview',
            welcomeCardHeader: "Welcome to Cloud NGFW powered by Palo Alto Networks!",
            welcomeLeftGrid: "Did you know? Global threats detected in Last 30 days",
            welcomeRightLeftSideGrid: "Cloud NGFW combines best in class network security with ease of use, and is delivered as a fully managed cloud native service by Palo Alto Networks. It natively integrates with AWS Firewall Manager, CloudWatch, Kinesis Firehose and other AWS services. It provides leading-edge threat prevention, application control, AppID, and advanced URL filtering. Easily deployed with just a few clicks, use Cloud NGFW to bring Palo Alto Networks best-in-class security and agility to your cloud deployments.",
            welcomeRightLeftSideGridSecondLine: "To get started, follow the guided steps below, or check our resources linked!",
            startQuickTour: "Start Quick Tour",
            NGFW: "NGFWs",
            rulestacks: "Rulestacks",
            getStarted: "Getting started with Cloud NGFW",
            vulnerabilityExploits: 'Vulnerability Exploits',
            malware: 'Malware',
            commandAndControl: 'Command and Control',
            ngfwsDef: 'NGFW protects your Virtual Private Cloud (VPC) traffic from threats including exploits, malware, and command control. NGFW can span multiple AWS availability zones',
            create: 'Create',
            ready: 'Ready',
            notStarted: 'Not started',
            terminated: 'Terminated',
            global: 'Global',
            local: 'Local',
            rulestacksDef: 'Rulestacks define access control and threat prevention for Cloud NGFW resources, and a Rulestack can be associated with multiple Cloud NGFW resources to share configuration.This ensures that only allowed traffic gets in while inspecting all content against Security Profiles and are used for Policy Management.',
            success: 'Success',
            failure: 'Failure',
            pending: 'Pending',
            onboarding: 'Onboarding STEP by STEP Guide',
            setUpProgress: 'Set up progress',
            completedProgress: ' of 3 recommended steps completed)',
            createRulestacks: '1. Create Rulestack',
            createRulesAndObjects: '2. Create Rule and Objects',
            createFirewalls: '3. Create Firewall & Setup Logging',
            threeMins: '3 minutes to complete',
            fiveMinutes: '5 minutes to complete',
            resouces: 'Resources',
            whatsNew: "What's New",
            about: 'About Cloud NGFW for AWS',
            faq: 'FAQ',
            serviceStatus: 'Cloud NGFW Service Status',
            createACase: 'Create a Case',
            deploymentGudie: 'Deployment Guide',
            liveCommunityLink: 'Live Community Link',
            aboutWithAws: 'Learn Cloud NGFW (Video Playlist)',
            globalTooltip: 'Panorama Administrator or AWS Firewall Administrator authors the global rulestacks. Panorama or AWS Firewall administrator can associate exactly one Global rulestack with a Cloud NGFW resource. The global rulestack includes multiple pre-rules, post-rules, and default rules.',
            localTooltip: 'Local rulestack administrator authors the local rulestacks. Local rulestack administrators can associate exactly one local rulestack with a Cloud NGFW resource.The local rulestack includes multiple local rules.',
            noPermissions: 'You do not have permission to run the guide.',
            onDismiss: 'Dismiss this guide',
            showGuide: 'Show step by step guide again',
            associated: 'Associated',
            notAssociated: 'Not Associated'
        },
        accounts: {
            name: 'AWS Accounts',
            fields: {
                Create: 'Launch CloudFormation Template',
                Download: 'Download CloudFormation Template',
                AccountId: 'AWS Account ID',
                XAccountIAMRoles: 'Cross Account IAM Roles',
                AccountProperty: 'Account Property',
                CheckDetails: 'Check Details',
                ExternalId: 'External ID',
                OnboardingStatus: 'Status',
                Actions: 'Actions',
                DownloadCFT: 'Download CFT',
                CreateButton: 'Add AWS Account',
                EmptyTitle: 'You have not configured an AWS account.',
                EmptySubtitle: 'Your AWS Account ID and External ID allow your account to execute a CFT that automatically creates AWS resources, including cross-account permissions and IAM roles that are specific to Cloud NGFW service firewalls. Cross-account permissions enable your Cloud NGFW service account to manage or interact with assets in your AWS.',
                ListTitle: 'Firewall Accounts',
                ListSubtitle: 'The following AWS accounts have been associated to your Cloud NGFW service account.',
                UnsubscribePendingTooltip1: 'To delete this account:',
                UnsubscribePendingTooltip2: '1. Sign in to the AWS Marketplace console and Choose Manage subscriptions.',
                UnsubscribePendingTooltip3: '2. On the Manage subscriptions page, select “Manage” next to Cloud Next-Generation Firewall. (PAYG with 15-Day Free Trial)',
                UnsubscribePendingTooltip4: '3. Choose Actions, and then select Cancel subscription.',
                Delete: "Delete",
                ManageXAccountRoles: "Manage Cross Account Roles",
                LoggingRoleArn: "Logging Role Arn",
                DecryptionRoleArn: "Decryption Role Arn",
                EndpointRoleArn: "Endpoint Role Arn",
                LoggingRole: "Logging Role",
                NetworkMonitoringRole: "Network Monitoring Role",
                DecryptionRole: "Decryption Role",
                NetworkMonitoringRoleArn:  "Network Monitoring Role Arn",
                EndpointRole: "Endpoint Role",
                InvalidArn: "Invalid Role Arn",
                EndpointRoleArnNote: "Note: Endpoint Role ARN can not be modifed once added.",
                LoggingRoleArnNote: "Note: Logging Role ARN can not be modifed once added.",
                DecryptionRoleArnNote: "Note: Decryption Role ARN can not be modifed once added.",
                NetworkMonitoringRoleArnNote: "Note: Network Monitoring Role ARN can not be modifed once added."
            },
            modal: {
                AccountPropertyDetails: 'Account Property Details',
                Close: 'Close'
            },
            content: {
                stepsTitle: "FOLLOW THE FOLLOWING STEPS",
                stepOne: "1. Log in to your AWS account and create a stack using the AWS console <b>Create Stack</b> on <b>AWS</b> or the AWS CLI.",
                stepTwo: "2. In the console, select <b>I acknowledge that AWS CloudFormation might create IAM resources with custom names</b> and press <b>Create Stack on AWS</b>.",
                stepThree: "3. After the status is <b>CREATE_COMPLETE</b>, copy the Role ARN from the Outputs tab.",
            },
            overviewPanel: {
                awsAccountId: "AWS Account ID",
                numberOfNgfws: "Number of NGFWs",
                numberOfCrossAccountRoleNames: "Number Of Cross Account Role Names",
                cftStatus: "CFT Status",
                awsConsole: "AWS Console"
            }
        },
        objects: {
            name: 'Objects'
        },
        securityservices: {
            name: 'Security Services'
        },
        rulestacksettings: {
            name: 'Settings'
        },
        associatedfirewalls: {
            name: 'Associated Firewalls'
        },
        endpoints: {
            name: 'Endpoints'
        },
        ngfwrules: {
            name: 'Rules'
        },
        firewallsettings: {
            name: 'Firewall Settings'
        },
        logsettings: {
            name: 'Log Settings'
        },
        rules: {
            name: 'Security Rules',
            title: 'Rules',
            fields: {
                appid: {
                    add: 'Add',
                    edit: 'Edit'
                },
                Create: {
                    LocalRule: 'Create Local Rule',
                    PreRule: 'Create Pre Rule',
                    PostRule: 'Create Post Rule'
                },
                Source: 'Source',
                SourceSubtitle: 'Match your traffic based on the Source match criteria',
                Destination: 'Destination',
                DestinationSubtitle: 'Match your traffic based on the Destination match criteria',
                MatchCriteria: 'Match Criteria',
                Application: 'Applications (App-ID&trade;)',
                ApplicationSubtitle: 'App-ID allows you to protect against a full spectrum of legal, regulatory, productivity and resource utilization risks.',
                URLFiltering: "URL Category",
                URLFilteringSubtitles: "URL Filtering allows you to protect against a full spectrum of legal, regulatory, productivity and resource utilization risks. ",
                ProtocolAndPorts: "Protocol & Ports",
                ProtocolAndPortsSubtitles: "Protocol and Port allow you to control traffic based on specified network services.",
                SourceAndDestination: 'Source And Destination',
                SourceAndDestinationSubtitle: 'Match your traffic based on the Source and Destination',
                CidrsNote: "Enter one value per line, and use CIDR format.",
                Cidrs: "Cidrs",
                PrefixList: "Prefix List",
                Countries: "Countries",
                FQDNList: "FQDN List",
                Feeds: "Feeds",
                General: 'General',
                URLCategory: 'URL Category',
                PortNumber: "Port Number",
                Protocol: "Protocol",
                ProtocolPortNote: "Port can be single port # (e.g., 80), or comma-separated (e.g., 80,443,8080)",
                RuleName: 'Name',
                Priority: 'Priority',
                Applications: 'Applications',
                Logging: 'Logging',
                DecryptionRuleType: 'TLS Decryption',
                InboundInspectCert: 'Inbound Inspection Certificate',
                InboundInspectCertError: {
                    suffix: ' if you do not have one already.',
                    link: 'Certificates',
                    prefix: 'Create a certificate for inbound inspection policy from ',
                },
                Action: 'Action',
                Constraints: 'Granular Controls',
                RulePriority: 'Rule Priority',
                Tags: 'Tags',
                Description: 'Description',
                Enabled: 'Enabled',
                EnabledSubtitle: 'This field is checked by default. If you uncheck this field, the rule is ignored when the associated rulestack is applied to traffic.',
                Rulestacks: 'Rulestacks',
                RuleStackName: 'Local Rulestack',
                RuleStackSubtitle: 'Select a Local Rulestack for this Firewall',
                ConstraintsSubtitle: 'Reduce the attack surface with these controls. App-ID and URL Filtering allow you to protect against a full spectrum of legal, regulatory, productivity and resource utilization risks. Protocol and Port allow you to control traffic based on specified network services.',
                ActionSubtitle: 'Specify the action the firewall takes when traffic matches one of the rules you created.',
                CreateButton: 'Create ',
                EmptyTitle: 'You have not created Rules.',
                EmptySubtitle: 'If you have not configured any local rules, the firewall applies Global Security rules to traffic. If no rules (local or global) have been applied to the firewall, all traffic is denied.',
                GlobalListSubtitle: 'A Global Rulestack configures pre-rules and post-rules on each NGFW. To create and manage global rulestacks, you must have the Global Rulestack Admin role.',
                LocalListSubtitle: 'Local account administrators can associate a Local Rulestack with an NGFW. A local rulestack includes local rules that apply to a specific firewall.',
                GlobalCreateSubtitle: 'Pre rules are applied to traffic before any security rules configured locally on a specific firewall. Post rules are applied to traffic after any security rules configured locally on a specific firewall.',
                allow: 'Allow',
                deny: 'Deny',
                resetServer: 'Reset Server',
                resetBoth: 'Reset Both',
                any: 'Any',
                match: 'Match',
                excludeNegateMatch: 'Exclude (Negate Match)',
                select: 'Select',
                applicationDefault: 'Application-Default',
                none: 'None (Default)',
                outbound: 'Decrypt (SSL Forward Proxy/SSL Outbound Decryption)',
                inbound: 'Decrypt (SSL Inbound Decryption)',
                inboundNoInspect: 'No Decrypt (SSL Inbound Decryption)',
                outboundNoInspect: 'No Decrypt (SSL Forward Proxy/SSL Outbound'
            },
            appsModalComponent: {
                applications: 'Applications',
                category: "Category",
                subCategory: "Subcategory",
                technology: "Technology",
                risk: "Risk",
                tags: "Tags",
                dependsOnApplications: 'Depends On Applications',
                addToSelectedApplications: 'Add to Selected Applications',
                remove: 'Remove',
                cancel: 'Cancel',
                discard: 'Discard',
                noDependApps: 'The selected applications have no dependent applications',
                noSelectedApps: 'You have not selected any applications yet',
                noCategorizedApps: 'No applications for the filters selected',
                addApps: "Add Applications",
                categorizedApps: 'Categorized Applications',
                selectedApps: 'Selected Applications',
                promptMsgTitle: "You have unsaved changes. Are you sure you want to proceed?",
                discardChanges: 'Discard Changes'
            },
            counters: {
                lastRefreshedDate: 'Last refreshed date',
                appSeen: 'APP SEEN',
                hitCount: 'HIT COUNT',
                reset: 'Reset',
                cancel: 'Cancel',
                resetHitCounter: 'Reset Hit Count',
                sureToResetCounter: 'Are you sure to reset the hit counter?',
            },
            panwdsSelectAndInput: {
                add: 'Add'
            }
        },
        tokens: {
            name: 'Tenant',
            title: 'Tenant',
            panels: {
                general: 'General',
                support: 'Palo Alto Networks Support'
            },
            fields: {
                externalId: 'External ID',
                proxyAccountId: 'CloudNGFW Account ID',
                snsTopicARN: 'SNS Topic ARN',
                access: 'Programmatic Access',
                supportAccount: 'Support Account',
                serialNumber: 'Serial Number',
                supportType: 'Support Type',
                tenantId: 'Tenant ID'
            },
            values: {
                enabled: 'Enabled',
                disabled: 'Disabled',
                enable: 'Enable',
                disable: 'Disable',
                cancel: 'Cancel',
                accessSubtitle: 'To use Programmatic Access, you need to enable this option. For more information, please refer to the ',
                accessSubtitleDoc: 'document'
            },
            confirm: {
                title: 'Enable Programmatic Access',
                description: 'Please confirm updating programmatic access.'
            },
            auditLog: {
                title: "Audit Log Settings",
                auditLog: "Audit Log",
                group: "Log Group ARN",
                edit: "Edit Audit Log",
                disabled: "Disabled",
                enabledCloudWatch: "Enabled (CloudWatch)"
            }
        },
        integrations: {
            name: 'Integrations',
            title: 'Integrations',
            listName: 'Policy Manager',
            actions: {
                add: 'Add Policy Manager',
                unlinkPanorama: 'Unlink Panorama',
                unlinkSCM: 'Unlink Strata Cloud Manager',
            },
            panels: {
                policy: 'Policy Management',
                log: 'Log Forwarding and Analytics',
            },
            fields: {
                // grid fields
                serialNumber: 'Panorama Serial Number / Tenant Name',
                haPeerSerialNumber: 'Panorama (HA Peer) Serial Number',
                policyManagementStatus: 'Status',
                logForwardingAndAnalyticsStatus: 'Log Forwarding and Analytics Status',
                // edit fields
                policyManagement: 'Policy Management',
                primaryPanoramaSN: 'Primary Panorama Serial Number',
                secondaryPanoramaSN: 'Secondary Panorama Serial Number',
                linkID: 'Link ID',
                region: 'Region',
                policyManagementLinkStatus: 'Policy Management Link Status',
                policyManagementLinkStatusNote: 'Please Push-Config in Panorama to complete the linking process',
                linkToCDL: 'Link to Strata Logging Service',
                linkToCDLSub: 'To use the Panorama, the Strata Logging Service must be linked',
                // common
                LinkName: 'Link Name',
                CDLID: 'SLS ID',
                status: 'Status',
                logForwarding: 'Log Forwarding and Analytics',
                actions: 'Actions',
                securityServices: 'Security Services',
                checkDetails: 'Check Details',
                type: 'Type',
                tenantName: 'Tenant Name',
                url: 'SCM Link'
            },
            values: {
                enabled: 'Enabled',
                disabled: 'Disabled',
                active: 'Active',
                inactive: 'Inactive',
                pending: 'Pending',
                autoSelect: 'Auto Select',
                actionRequired: 'Action Required',
            },
            buttons: {
                edit: 'Edit'
            },
            panel: {
                // Panorama Detail
                panoramaDetail: "Link Panorama",
                // Edit Policy Management Sidepanel
                LinkName: 'Link Name',
                editPolicyManagement: 'Edit Policy Management',
                addPanorama: 'Add Panorama',
                primaryPanoramaSN: 'Primary Panorama Serial Number',
                secondaryPanoramaSN: 'Secondary Panorama Serial Number',
                haPeerSerialNumber: 'Panorama (HA Peer) Serial Number',
                logAnalytics: 'Log Analytics',
                notePM: 'The policy management will be automatically enabled once you saved the changes',
                policyManagement: 'Policy Management',
                linkingFailed: 'Panorama linking failed. Please a valid Panorama HA pair.',
                panoramaNotEnabled: "This Panorama is not configured to use a Palo Alto Networks Strata Logging Service (SLS) account. Please choose one of the options below:",
                panoramaCancel: "I agree to CANCEL the linking process. Before continuing with the linking process, I will procure a SLS account from Palo Alto Networks and configure it with my Panorama.",
                panoramaAgree: "I agree to continue with the linking process by agreeing to use Panorama for Policy Management. I agree that I will just forward NGFW logs to other AWS destinations (S3, Kinesis, or Cloudwatch).",
                panoramaAllow: "I agree to continue with the linking process by allowing Cloud NGFW to create a SLS account on my behalf.",
                selectRegion: "Select Region for Configurations",
                selectRegionAllow: "Select Region for Logs and Configurations",
                pendingDescription: "The Cloud NGFW tenant is unlinking from Panorama. This page will refresh automatically.",
                // Edit Log Forwarding and Analytics Sidepanel
                editLogForwarding: 'Edit Log Forwarding and Analytics',
                cdlID: 'SLS ID',
                addNew: 'Add New',
                // add new SLS ID
                back: 'Back',
                region: 'Region',
                autoSelect: 'Auto Select',
                addNewCDL: 'Add New SLS ID',
                addPolicyManager: 'Add Policy Manager',
                tenantName: 'Tenant Name',
                name: 'Name'

            },
            modal: {
                // notification modal
                notification: 'Notification',
                warning: 'Warning',
                descriptionLinked: 'Panorama linking is complete. The linked Panorama is associated with a Palo Alto Networks Strata Logging Service (SLS) account. Please confirm to link the Cloud NGFW tenant with the same SLS account.',
                descriptionNotLinked: 'Panorama linking is complete. The linked Panorama is NOT previously associated with a Palo Alto Networks Strata Logging Service (SLS) account. Please confirm to enable a new SLS account.',
                descriptionUnlinkConfirm: 'Are you sure you want to unlink Panorama from this [Cloud NGFW tenant]?',
                noteUnlinkConfirm: 'This action deletes your link with Panorama. If the Panorama is associated with a Strata Logging Service (CDL) account, this action also deletes your link with the SLS and prunes the logs after the retention period.',
                descriptionErrorUnlink: "You have one or more Cloud Device Groups in Panorama that may be associated with Cloud NGFW resource(s) or region(s). We recommend deleting the following Cloud Device Groups before you unlink the Panorama.",
                noteErrorUnlink: "If you cannot access Panorama, you can choose to force unlink.",
                // select SLS account modal
                selectCDL: 'Select SLS Account',
                selectDescription: 'Multiple SLS accounts have been found in this tenant. Please select one to link.',
                cdlAccount: 'SLS Account',
                descriptionCMUnlinkConfirm: 'Are you sure you want to unlink Strata Cloud Manager from this [Cloud NGFW tenant]?',
                noteCMUnlinkConfirm: 'This action deletes your link with Strata Cloud Manager. If the Strata Cloud Manager is associated with a Strata Logging Service (SLS) account, this action also deletes your link with the SLS and prunes the logs after the retention period.'
            },
            securityService: {
                title: "Security Services",
                dlp: "Data Loss Prevention",
                awsPlugin: "AWS Plugin",
                compatibilityMatrix: "document",
                defaultSecurityServices: "Default Security Services",
                addOnSecurityServices: "Add-on Security Services",
                dlpLicenceNotAvailable: "DLP license is not available in this tenant ({externalId}). You can not disable DLP in this console once enabled. To disable it, please",
                dlpLicenceBeingCreated: "DLP license is currently being created for this tenant ({externalId}). You can not disable DLP in this console once enabled. To disable it, please",
                dlpLicenceAvailable: "DLP license is currently available in this tenant ({externalId}). To disable it, please",
                dlpLicenceRequisite: "Make sure you complete the following steps in your Panorama to ensure that everything is set up correctly:" +
                    "{compatibilityList}" +
                    "For more details on Panorama and DLP version compatibility, please check this {compatibilityMatrix}",
                compatibilityListValue1: "Use PAN-OS version 10.2.4 and above.",
                compatibilityListValue2: "Install the AWS Plugin Version 5.2.0 or above.",
                compatibilityListValue3: "Install Data Loss Prevention (DLP) Plugin Version 3.0.7 or above.",
                compatibilityListValue4: "DLP tenant should be provisioned on Panorama using CLI (request plugins dlp provision-tenant) when No DLP Tenant provisioned on Panorama.",
                modal: {
                    dlp: "Are you sure you want to enable the Data Loss Prevention (DLP)?",
                    enableDlp: "Please note that charges apply only when enquiring about DLP policies via Panorama policies.",
                    tAndC: "Check here to indicate that you have read and agree to the terms of the",
                    endUserAgreement: "End User License Agreement",
                }
            },
            // common
            note: 'Note',
            ok: "Ok",
            close: "Close",
            cancel: 'Cancel',
            save: 'Save',
            unlink: 'Unlink',
            confirm: 'Confirm',
            continue: 'Continue',
            force: 'Force Unlink',
            enable: 'Enable',
        },
        usage: {
            name: 'Usage Explorer',
            downloadAsCsv: 'Download as CSV',
            noUsageHistory: 'No usage history for this tenant',
            period: 'Period',
            dimension: 'Dimension',
            dimensionTooltip: 'Used to identify billing for a NGFW. A dimension may refer to an add-on (Threat Prevention), or a tenant\'s consumption of secured traffic.',
            purchasedCredits: 'Purchased Credits',
            purchasedCreditsTooltip: 'The number of credits purchased for use by the tenant.',
            consumedUnits: 'Consumed Units',
            consumedUnitsTooltip: 'The amount of the resource consumed by the tenant during the billing period.',
            consumedCredits: 'Consumed as Credits',
            consumedCreditsTooltip: 'The amount of procured credits (paid for as an upfront cost) consumed by the tenant.',
            usageHistory: 'Usage History',
            totalCredits: 'Total Credits',
            beta: 'BETA'
        },
        support: {
            name: 'Subscription',
            title: 'Subscription',
            fields: {
                supportInfo: 'Palo Alto Networks Premium Support offering enhances your in-house resources with technical experts available to support your Palo Alto Networks security infrastructure.',
                supportInfoSecondPara: 'This support level ensures faster SLAs (<1 hr for critical issues), and 24x7 phone access once a case has been filed on our Customer Support Portal. It also gives you the required assistance when security incidents occur, and you need to augment your staff with security experts to achieve faster resolutions.',
                supportRequirement: 'A Palo Alto Networks Customer Support Portal (CSP) account is required to enable Premium Support. If you have not done so already, you can create a new account at support.paloaltonetworks.com. It can take up to 24 hours for the CSP to complete the upgrade to Premium Support.',
                subscribe: 'Subscribe Monthly Premium Support Subscription',
                supportAccount: 'Support Account',
                supportSKU: 'Support SKU ID',
                supportTenant: 'Support Tenant ID',
            },
            values: {
                subscribed: 'Subscribed',
                subscribe: 'Subscribe',
                disable: 'Disable',
                cancel: 'Cancel'
            },
            confirm: {
                title: 'Confirmation',
                description: 'Do you want to subscribe to Monthly Premium Support?'
            },
            credits: {
                totalCredits: 'Total Credits',
                usage: 'Usage'
            },
            panels: {
                support: 'Premium Support'
            },
            subscription: {
                title: "AWS Marketplace Subscription Type",
                name: "Name",
                status: "Status",
                creditsTotal: "Total Credits",
                overridenInfo: "While Contract credits have been applied, you will not be billed on Pay as you go, however we recommend staying subscribed, if you forget to renew your SaaS Contract on time you will not lose your firewalls and configuration, as it falls back to PAYG",
                endDate: "End Date",
                accountId: "AWS Account ID",
                overriden: "Overriden",
                trialEndDateMessage: "Free trial until",
                paygStatus: "(Your consumption will be billed)",
                na: 'N/A',
                subscriptionCreditUsage: {
                    subscriptionManagement: 'Subscription Management',
                    creditUsage: 'Credit Usage',
                    dimensions: 'Dimensions',
                    fwUsageHours: 'FW Usage Hours',
                    tpTrafficSecured: 'TP Traffic Secured',
                    tpUsageHours: 'TP Usage Hours',
                    urlFUsageHours: 'URLF Usage Hours',
                    enhancedSupport: 'Enhanced Support',
                    overagesPayG: 'Overages PAYG',
                    all: 'All',
                    creditType: 'Credit Type',
                    contract: 'Contract',
                    flexCredit: 'Flex credit',
                    payG: 'PAYG',
                    dates: 'Dates',
                    usedCredits: 'Used Credits',
                    averageCredits: 'Average Credits',
                    purchasedCredits: 'Purchased Credits',
                    noUsageByTheTenant: 'No usage by the tenant',
                    creditUsageSentenceCase: 'Credit usage',
                }
            },
        },
        prefix: {
            name: 'Prefix List',
            fields: {
                General: 'General',
                Name: 'Name',
                PrefixList: 'Address',
                Description: 'Description',
                AddressSubtitle: 'Enter one value per line, and use CIDR format.',
                CreateButton: 'Create Prefix',
                EmptyTitle: 'You have not created Prefixes.',
                EmptySubtitle: 'If you have not configured any local rules, the firewall applies Global Security rules to traffic. If no rules (local or global) have been applied to the firewall, all traffic is denied.',
                ListSubtitle: 'A Prefix List is security policy object that allow you to group specific source or destination IP addresses that require the same policy enforcement. IP address objects must be defined in CIDR format.',
                CreateSubtitle: 'Enter an IP address or IP netmask in CIDR notation to create a Prefix object.',
            },
            delete: {
                success: "Prefix lists deleted succesfully.",
            },
        },
        fqdn: {
            name: 'FQDN List',
            fields: {
                General: "General",
                Name: 'Name',
                Priority: 'FQDN',
                Description: 'Description',
                FqdnList: 'FQDN',
                FqdnSubtitle: 'Enter one value per line, and use CIDR format.',
                CreateButton: 'Create FQDN',
                EmptyTitle: 'You have not created FQDN.',
                EmptySubtitle: 'If you have not configured any local rules, the firewall applies Global Security rules to traffic. If no rules (local or global) have been applied to the firewall, all traffic is denied.',
                ListSubtitle: 'An FQDN List is security policy object that allows you to group specific source or destination Fully-Qualified Domain Names (FQDN) that require the same policy enforcement. Because FQDNs can be translated to many different IP addresses, using an FQDN object can be more efficient than specifying specific IP addresses.',
                CreateSubtitle: 'Enter a fully-qualified domain name (FQDN) to create an FQDN object.'
            },
            delete: {
                success: "FQDN lists deleted succesfully.",
            },
        },
        category: {
            name: 'Custom URL Category',
            fields: {
                General: "General",
                Action: "Action",
                Name: 'Name',
                URLTargets: 'URL List',
                Description: 'Description',
                CategorySubtitle: 'Enter one value per line.',
                CreateButton: 'Create Custom URL Category',
                EmptyTitle: 'You have not created Categories.',
                EmptySubtitle: 'If you have not configured any local rules, the firewall applies Global Security rules to traffic. If no rules (local or global) have been applied to the firewall, all traffic is denied.',
                ListSubtitle: 'A custom URL category allows you specify exceptions to URL category enforcement and to create a custom URL category based on multiple existing categories.',
                CreateSubtitle: 'A custom URL category allows you specify exceptions to URL category enforcement and to create a custom URL category based on multiple existing categories.',
            },
            delete: {
                success: "Custom URL Categories deleted succesfully.",
            },
        },
        feed: {
            name: 'Intelligent Feed',
            fields: {
                Name: 'Name',
                General: "General",
                FeedSource: "Feed Source",
                RuleName: 'Name',
                Type: 'Type',
                FeedURL: 'Source',
                Frequency: 'Frequency',
                CertificateProfile: "Certificate Profile",
                UpdatesFrequency: "Updates Frequency",
                Description: 'Description',
                FQDNSubtitle: 'Enter one value per line.',
                CreateButton: 'Create Intelligent Feed',
                EmptyTitle: 'You have not created Feeds.',
                EmptySubtitle: 'If you have not configured any local rules, the firewall applies Global Security rules to traffic. If no rules (local or global) have been applied to the firewall, all traffic is denied.',
                ListSubtitle: 'An Intelligent Feed object allows you to specify a one or more external web servers that provide a list of objects, such as IP addresses, URLs, and domains, that you can use to enforce security policy.',
                CreateSubtitle: 'Create an intelligent feed object to enforce policy based on list of objects, such as IP addresses, URLs, and domains, collected on an external web server.',
            },
            delete: {
                success: "Intelligent Feed deleted successfully.",
                notAllowed: "PaloAlto Networks default Intelligent Feed - Delete not allowed "
            },
        },
        certificate: {
            name: 'Certificates',
            fields: {
                General: "General",
                Name: 'Name',
                Source: 'Source',
                CertificateSignerArn: "Certificate ARN",
                CertificateSelfSigned: "Self Signed",
                SelfSignedCertificate: "Self Signed Certificate",
                SelfSignedCertificateSubtitle: "Inbound decryption does not support self-signed certificates.",
                Description: 'Description',
                CreateButton: 'Add Certificate',
                EmptyTitle: 'You have not created Certifications.',
                EmptySubtitle: 'If you have not configured any local rules, the firewall applies Global Security rules to traffic. If no rules (local or global) have been applied to the firewall, all traffic is denied.',
                ListSubtitle: 'Add certificates to decrypt and inspect the SSL traffic leaving your network to prevent exfiltration of private or sensitive data, you must provide the certificates needed to decrypt the traffic.',
                CreateSubtitle: 'Add a certificate used by inbound or outbound decryption policy.'
            },
            delete: {
                success: "Certificates deleted Successfully.",
            },
        },
        fileBlocking: {
            name: 'File Blocking',
            fields: {
                Name: 'Name',
                FileTypeName: 'File Type Name',
                Action: 'Action',
                Direction: 'Direction',
                ListSubtitle: '',
            },
            actionOverride: 'Action is same as default action. Nothing to override.',
            directionOverride: 'Direction is same as default direction. Nothing to override.',
            dropdown: {
                alert: 'Alert',
                block: 'Block',
                continue: 'Continue',
                upload: 'Upload',
                download: 'Download',
                both: 'Both',
                setAction: 'Set Action',
                setDirectionOfTraffic: 'Set Direction of traffic'
            }
        },
        urlFiltering: {
            name: 'URL Filtering',
            fields: {
                Name: 'Category',
                Action: 'Site Access',
            },
            noOverride: 'Same as predefined site access. Nothing to override.',
            dropdown: {
                alert: 'Alert',
                allow: 'Allow',
                block: 'Block',
                none: 'None',
                setSiteAccess: 'Set Site Access'
            },
            predefinedCategories: 'Predefined Categories',
            customCategories: 'Custom Categories'
        },
        outboundDecryptionCert: {
            name: 'Outbound Decryption',
            fields: {
                UntrustCert: 'UnTrust Certificate',
                TrustCert: 'Trust Certificate',
            }
        },
    },
}

export default englishMessages;