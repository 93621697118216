import { useTranslate } from "../../customHooks";
import { composeValidators } from "../../utils/validate";
import { useCallback } from "react";
import { ModalHeader, ModalBody, ModalFooter } from "@panwds/react-ui";
import { Form, FormLayout, Input, SubmitButton } from "@panwds/react-form";

function AddNewTagForm({
  closeModal,
  isOpen,
  onSubmit,
  title,
  validate,
  data,
}) {
  
  const translate = useTranslate();
  const maxKeyLength = 128;
  const maxValueLength = 256;

  const isKeyUnique = useCallback((value: string) => {
    if (!value) return undefined; // If no value, no validation error.
    const isDuplicate = data?.some(item => item?.Key === value);
    return isDuplicate ? 'Key must be unique' : undefined;
  }, [data]);

  return (
    <Form
      modalProps={{ onClose: closeModal, isOpen }}
      onSubmit={(newTag) => {
        onSubmit((previous) => {
          return [...previous, newTag]
        });
        closeModal();
      }}
    >
      <ModalHeader title={title } />
      <ModalBody addClassName="twds-overflow-auto twds-scrollbar-default dark:twds-scrollbar-track-dark dark:twds-scrollbar-thumb-dark dark:twds-scrollbar-corner-dark">
        <FormLayout>
          <Input
              label={translate(`chip.key`)}
              name="Key"
              dataMetrics="cloudngfw-add-tag-key"
              register={{ validate: (value) => composeValidators(maxKeyLength, isKeyUnique, validate)(value) }}
          />
          <Input
              label={translate(`chip.value`)}
              name="Value"
              dataMetrics="cloudngfw-add-tag-value"
              register={{ validate: (value) => composeValidators(maxValueLength)(value) }}
          />
        </FormLayout>
      </ModalBody>
      <ModalFooter>
        <SubmitButton />
      </ModalFooter>
    </Form>
  );
}

export default AddNewTagForm;