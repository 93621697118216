import React from 'react';
import { useTranslate } from '../../../../../customHooks';
import Skeleton from '@mui/material/Skeleton';

interface EndpointProps {
    firewall: any;
    styles: any;
    isLoading: boolean;
}

const EndpointsOverview: React.FC<EndpointProps> = ({ firewall, styles, isLoading }) => {
    const translate = useTranslate();
    
     /* TODO: Needs a revisit with response data*/
    return (
        <div className={styles.flexContainer}>
            <h2 className={styles.title}>{translate(`resources.firewallsV2.endpoints`)}</h2>
            <div className={styles.flexColumn}>
                <div className={styles.flexRow}>
                    <strong className={styles.flexRowHeader}>{translate(`resources.firewallsV2.vpcEndpointService`)}</strong>
                    <span className={styles.flexRowValues}>{isLoading ? <Skeleton variant="text" width="50px" height="30px" /> : firewall?.UserID?.UserIDConfig}</span>
                </div>
                <div className={styles.flexRow}>
                    <strong className={styles.flexRowHeader}>{translate(`resources.firewallsV2.numberOfEndpoints`)}</strong>
                    <span className={styles.flexRowValues}>{isLoading ? <Skeleton variant="text" width="50px" height="30px" /> : firewall?.UserID?.UserIDStatus}</span>
                </div>
                <div className={styles.flexRow}>
                    <strong className={styles.flexRowHeader}>{translate(`resources.firewallsV2.endpointsStatus`)}</strong>
                    <span className={styles.flexRowValues}>{isLoading ? <Skeleton variant="text" width="50px" height="30px" /> : firewall?.UserID?.EndpointId}</span>
                </div>
            </div>
        </div>
    );
};

export default EndpointsOverview;