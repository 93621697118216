import { useTranslate } from "../../../../customHooks";
import { composeValidators, firewallNameKeyNotAllowed, isAlphaNumeric, isRequired, maxElements, maxLength } from "../../../../utils/validate";
import { usePermissions } from '../../../../customHooks';
import { ApplicationConfigManager, SupportedRegion } from "../../../../types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "../../../../components";
import { FormLayout, Input, Select, SubmitButton, ChipInput } from "@panwds/react-form";
import { useGetRegionsQuery } from "../../../../redux/services/regions-service";
import { useFilteredPermissions } from "../../../../customHooks/useFilteredPermissions";
import { find, isEmpty } from 'lodash';
import { getFirewallName, getFirewallTags } from "../../firewallsUtil";
import { useUpdateFirewallMutation } from "../../../../redux/services/firewalls-service";
import { extractFieldsWithConfig } from "../../../../utils/utils";
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { Button, LoadingPanel, ModalProvider } from "@panwds/react-ui";
import { TagsWithAdd } from "../../../../components/FormElements";

const FirewallGeneralInfo = (props: {firewallData: Record<string, any>, isLoading: boolean}) => {
    const { filteredDeleteProtectionOptions } = useFilteredPermissions();
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const region = new URLSearchParams(location.search).get('region');
    const [updaFirewall] = useUpdateFirewallMutation();

    const { data: zones, error, isLoading: isZonesLoading, refetch: refetchZones } = useGetRegionsQuery(region, {
        skip: !region,
    });

    const regionItems = ApplicationConfigManager.getInstance().getSupportedRegions().map((region: SupportedRegion) => ({
        text: region.RegionDisplayName,
        value: region.RegionCode
    }));
    const regionDisplayName = find(regionItems, (regionItem) => (regionItem.value === region));

    const config = {
        Firewall: ['FirewallName', 'FirewallId', 'Region', 'CustomerZoneIdList', 'DeletionProtection', 'Description', 'Tags', 'UpdateToken'],
    };

    // Memoized function to transform the default form values
    const transformFormDefaultValue = useMemo(() => {
        if (isEmpty(props?.firewallData)) {
            return undefined;
        }
        // Extract fields from firewall describe response based on the config. 
        // Extracts fields needed for firewall general info form.
        const newStateWithConfig = extractFieldsWithConfig(props?.firewallData, config);
        return {
            Firewall: {
                ...newStateWithConfig.Firewall,
                Region: (regionDisplayName) ? regionDisplayName['text'] : region || '',
                FirewallName: getFirewallName(newStateWithConfig),
                Tags: newStateWithConfig.Firewall?.Tags.filter((tag) => tag?.Key !== "FirewallName") || [],
            }
        }
    }, []);

      /**
    * Transforms the form data for firewall update submission.
    * This function processes the form data, applies necessary transformations,
    * and prepares the data structure required for the firewall general info update.
    */
    const transformFormSubmitValue = useCallback((formData: Record<string, any>) => {
        if (isEmpty(formData)) {
            return;
        }
        const transformedData = {
            ...formData.Firewall,
            Tags: getFirewallTags(formData.Firewall.Tags, formData.Firewall.FirewallName),
            Region: region
        }
        delete transformedData['FirewallName'];
        return transformedData;
    }, []);

    const formMethods = useForm({ defaultValues: transformFormDefaultValue });
    const {
        formState: { isDirty }, control, reset,
	} = formMethods
    
    const onSubmit = useCallback(
        async (data) => {
            try {
                const result = await updaFirewall({
                    payload: transformFormSubmitValue(data),
                    region: region || '',
                }).unwrap();
            }
            catch (error: any) {
                toast.error(`${error?.code}: ${error?.error}`, {toastId: "update-firewall"});
            }
        }, [history, permissions]
    );

    const handleCancel = () => {
        reset(transformFormDefaultValue);
    };

    return (
        <div>
            {/* <div className="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
                {props?.isLoading && <LoadingPanel />}
            </div> */}
            <FormProvider {...formMethods}>
                <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                    <FormLayout>
                        <Input
                            label={translate(`resources.firewallsV2.firewallName`)}
                            name="Firewall.FirewallName"
                            dataMetrics="cloudngfw-firewall-edit-name"
                            description={translate(`resources.firewallsV2.firewallNameEditDescription`)}
                            register={{ required: false, validate: (value) => composeValidators(isAlphaNumeric, maxLength(128))(value) }}
                        />
                        <Input
                            label={translate(`resources.firewallsV2.firewallId`)}
                            name="Firewall.FirewallId"
                            dataMetrics="cloudngfw-firewall-edit-firewallId"
                            disabled
                            description={translate(`resources.firewallsV2.firewallIdEditDescription`)}
                        />
                        <Input
                            label={translate(`resources.firewallsV2.region`)}
                            name="Firewall.Region"
                            dataMetrics="cloudngfw-firewall-edit-region"
                            description={translate(`resources.firewallsV2.regionEditDescription`)}
                            disabled
                        />
                        <Select
                            items={zones}
                            label={translate(`resources.firewallsV2.availabilityZoneIDs`)}
                            name="Firewall.CustomerZoneIdList"
                            dataMetrics={"cloudngfw-firewall-edit-availability-zone"}
                            isLoading={isZonesLoading}
                            enableMultiSelect
                            simpleFormat
                        />
                        <Select
                            items={filteredDeleteProtectionOptions}
                            label={translate(`resources.firewallsV2.deleteProtection`)}
                            name="Firewall.DeletionProtection"
                            dataMetrics={"cloudngfw-firewall-edit-delete-protection"}
                            enableMultiSelect
                            simpleFormat
                            disabled
                        />
                        <Input
                            label={translate(`resources.firewallsV2.description`)}
                            name="Firewall.Description"
                            dataMetrics="cloudngfw-firewall-edit-description"
                            register={{ required: false }}
                        />
                        <Controller
                            name="Firewall.Tags"
                            control={control}
                            render={({ field }) => (
                                <TagsWithAdd label={translate('generic.tags')} {...field} validate={firewallNameKeyNotAllowed} />
                            )}
                        />
                        
                        <div className="tw-flex tw-justify-end">
                            <Button addClassName="tw-mr-2" onClick={handleCancel} disabled={!isDirty}>
                                {translate(`generic.cancel`)}
                            </Button>
                            <SubmitButton disabled={!isDirty}>
                                {translate(`generic.save`)}
                            </SubmitButton>
                        </div>
                    </FormLayout>
                </form>
            </FormProvider>
        </div>
    );
};export default FirewallGeneralInfo;